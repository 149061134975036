import React, { useState, useEffect } from 'react';
import { Upload, Image as ImageIcon, AlertCircle } from 'lucide-react';

export function LogoSettings() {
  const [logo, setLogo] = useState<string>(() => {
    return localStorage.getItem('companyLogo') || '';
  });
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (logo) {
      localStorage.setItem('companyLogo', logo);
    }
  }, [logo]);

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }

    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      setError('Image size should be less than 5MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const dataUrl = e.target?.result as string;
      setLogo(dataUrl);
      setError('');
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveLogo = () => {
    setLogo('');
    localStorage.removeItem('companyLogo');
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Company Logo</h2>
        <p className="mt-1 text-sm text-gray-500">
          Upload and manage your company logo
        </p>
      </div>
      
      <div className="p-6">
        {error && (
          <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-400 text-red-700">
            <div className="flex">
              <AlertCircle className="h-5 w-5 text-red-400" />
              <p className="ml-3">{error}</p>
            </div>
          </div>
        )}

        <div className="space-y-4">
          {logo ? (
            <div className="relative w-48 h-24 bg-gray-50 rounded-lg overflow-hidden">
              <img
                src={logo}
                alt="Company Logo"
                className="w-full h-full object-contain"
              />
              <button
                onClick={handleRemoveLogo}
                className="absolute top-2 right-2 p-1 bg-red-100 rounded-full text-red-600 hover:bg-red-200"
              >
                <AlertCircle className="w-4 h-4" />
              </button>
            </div>
          ) : (
            <div className="w-48 h-24 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center">
              <ImageIcon className="w-8 h-8 text-gray-400" />
            </div>
          )}

          <div>
            <label className="relative cursor-pointer">
              <input
                type="file"
                className="sr-only"
                accept="image/*"
                onChange={handleLogoUpload}
              />
              <div className="inline-flex items-center px-4 py-2 bg-aws-blue text-white rounded-md hover:bg-aws-navy">
                <Upload className="w-4 h-4 mr-2" />
                {logo ? 'Change Logo' : 'Upload Logo'}
              </div>
            </label>
            <p className="mt-2 text-xs text-gray-500">
              Recommended size: 200x100px. Max file size: 5MB
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}