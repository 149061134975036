import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { PaymentList } from './PaymentList';
import { PaymentForm } from './PaymentForm';
import { PaymentApproval } from './PaymentApproval';
import { usePayments } from '../../hooks/usePayments';

export function PaymentManagement() {
  const { payments, loading, error, approvePayment, rejectPayment } = usePayments();
  const [showForm, setShowForm] = useState(false);

  if (loading) {
    return (
      <div className="p-8">
        <div className="aws-card p-6">
          <p className="text-gray-600">Loading payment data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-8">
        <div className="aws-card p-6">
          <p className="text-red-600">Error loading payments: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Payment Management</h1>
          <p className="text-gray-500 mt-1">Create and manage payment instructions</p>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="aws-button-primary"
        >
          <Plus className="w-4 h-4 mr-2" />
          New Payment
        </button>
      </div>

      {showForm ? (
        <PaymentForm onClose={() => setShowForm(false)} />
      ) : (
        <>
          <PaymentApproval 
            payments={payments || []}
            onApprove={approvePayment}
            onReject={rejectPayment}
          />
          <PaymentList payments={payments || []} />
        </>
      )}
    </div>
  );
}