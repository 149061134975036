import React from 'react';
import { Settings } from 'lucide-react';
import { BankAPIConfig } from './BankAPIConfig';
import { AccountMappings } from './AccountMappings';
import { TransactionRules } from './TransactionRules';
import { CategorySettings } from './CategorySettings';
import { ThemeSettings } from './ThemeSettings';
import { LogoSettings } from './LogoSettings';
import { EmailSettings } from './EmailSettings';

export function APISettings() {
  return (
    <div className="space-y-6 p-6">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Settings</h1>
          <p className="mt-1 text-sm text-gray-500">
            Configure application settings and integrations
          </p>
        </div>
        <Settings className="w-8 h-8 text-gray-400" />
      </div>

      <div className="grid grid-cols-1 gap-6">
        <LogoSettings />
        <ThemeSettings />
        <EmailSettings />
        <CategorySettings />
        <BankAPIConfig />
        <AccountMappings />
        <TransactionRules />
      </div>
    </div>
  );
}