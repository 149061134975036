import React, { useRef, useState } from 'react';
import { Upload, AlertCircle } from 'lucide-react';
import Papa from 'papaparse';
import type { CashflowEntry } from '../../../types/cash';

interface CashflowUploadProps {
  onUpload: (entries: Omit<CashflowEntry, 'id'>[]) => void;
}

export function CashflowUpload({ onUpload }: CashflowUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | null>(null);

  const validateEntry = (row: any, index: number): Omit<CashflowEntry, 'id'> => {
    // Get active categories from localStorage
    const savedCategories = localStorage.getItem('cashflowCategories');
    const activeCategories = savedCategories ? JSON.parse(savedCategories) : [];

    // Required fields check
    if (!row.date || !row.type || !row.category || !row.amount || !row.probability) {
      throw new Error(`Row ${index + 2}: Missing required fields`);
    }

    // Date validation
    const date = new Date(row.date);
    if (isNaN(date.getTime())) {
      throw new Error(`Row ${index + 2}: Invalid date format`);
    }

    // Type validation
    if (!['inflow', 'outflow'].includes(row.type)) {
      throw new Error(`Row ${index + 2}: Type must be either 'inflow' or 'outflow'`);
    }

    // Category validation
    if (activeCategories.length > 0) {
      const validCategory = activeCategories.some((cat: any) => 
        cat.name === row.category && cat.type === row.type && cat.active
      );
      if (!validCategory) {
        throw new Error(`Row ${index + 2}: Invalid category "${row.category}" for type "${row.type}"`);
      }
    }

    // Amount validation
    const amount = parseFloat(row.amount);
    if (isNaN(amount) || amount <= 0) {
      throw new Error(`Row ${index + 2}: Amount must be a positive number`);
    }

    // Probability validation
    const probability = parseInt(row.probability, 10);
    if (isNaN(probability) || probability < 0 || probability > 100) {
      throw new Error(`Row ${index + 2}: Probability must be between 0 and 100`);
    }

    return {
      date: date.toISOString(),
      type: row.type as 'inflow' | 'outflow',
      category: row.category,
      amount,
      probability,
      notes: row.notes || ''
    };
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        try {
          const entries = results.data.map((row: any, index: number) => 
            validateEntry(row, index)
          );
          
          onUpload(entries);
          setError(null);
          
          // Reset file input
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Failed to parse CSV file');
        }
      },
      error: (error) => {
        setError(`Failed to parse CSV file: ${error.message}`);
      }
    });
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      const input = fileInputRef.current;
      if (input) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        input.files = dataTransfer.files;
        input.dispatchEvent(new Event('change', { bubbles: true }));
      }
    } else {
      setError('Please upload a CSV file');
    }
  };

  return (
    <div className="mb-6">
      <div
        className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          ref={fileInputRef}
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          className="hidden"
        />
        <div className="flex flex-col items-center">
          <Upload className="w-12 h-12 text-gray-400 mb-2" />
          <p className="text-gray-600 mb-2">
            Drag and drop your CSV file here, or{' '}
            <button
              onClick={() => fileInputRef.current?.click()}
              className="text-aws-blue hover:text-aws-navy"
            >
              browse
            </button>
          </p>
          <p className="text-sm text-gray-500">
            File must be in CSV format with headers: date, type, category, amount, probability, notes
          </p>
        </div>
      </div>

      {error && (
        <div className="mt-4 p-4 bg-red-50 rounded-md flex items-start">
          <AlertCircle className="w-5 h-5 text-red-400 mt-0.5 mr-2" />
          <p className="text-sm text-red-700">{error}</p>
        </div>
      )}

      <div className="mt-4">
        <h4 className="text-sm font-medium text-gray-700 mb-2">Sample CSV Format:</h4>
        <pre className="bg-gray-50 p-4 rounded-md text-xs overflow-x-auto">
          date,type,category,amount,probability,notes{'\n'}
          2024-03-01,inflow,Customer Payments,75000,95,Monthly revenue{'\n'}
          2024-03-15,outflow,Operating Expenses,45000,100,Regular expenses
        </pre>
      </div>
    </div>
  );
}