interface ABARecord {
  bsb: string;
  accountNumber: string;
  amount: number;
  accountName: string;
  reference: string;
  traceBSB: string;
  traceAccount: string;
}

export function generateABAFile(records: ABARecord[], descriptiveRecord: {
  financialInstitution: string;
  userIdentification: string;
  description: string;
  processingDate: Date;
}): string {
  let content = '';
  let totalAmount = 0;
  let recordCount = 0;

  // Descriptive Record (Record Type 0)
  content += '0'; // Record Type
  content += '1'; // Reel Sequence Number
  content += ' '.repeat(2); // Reserved
  content += descriptiveRecord.financialInstitution.padEnd(3);
  content += descriptiveRecord.userIdentification.padEnd(26);
  content += descriptiveRecord.description.padEnd(12);
  content += formatDate(descriptiveRecord.processingDate);
  content += ' '.repeat(40);
  content += '\n';

  // Detail Records (Record Type 1)
  records.forEach(record => {
    content += '1'; // Record Type
    content += formatBSB(record.bsb);
    content += formatAccountNumber(record.accountNumber);
    content += ' '; // Transaction Code
    content += formatAmount(record.amount);
    content += record.accountName.padEnd(32);
    content += record.reference.padEnd(18);
    content += formatBSB(record.traceBSB);
    content += formatAccountNumber(record.traceAccount);
    content += '\n';

    totalAmount += record.amount;
    recordCount++;
  });

  // File Total Record (Record Type 7)
  content += '7';
  content += '999-999'; // BSB
  content += ' '.repeat(12);
  content += formatAmount(totalAmount);
  content += ' '.repeat(24);
  content += recordCount.toString().padStart(6, '0');
  content += ' '.repeat(40);

  return content;
}

function formatDate(date: Date): string {
  return date.toISOString().slice(0, 10).replace(/-/g, '');
}

function formatBSB(bsb: string): string {
  return bsb.replace(/[^0-9]/g, '').padStart(6, '0');
}

function formatAccountNumber(account: string): string {
  return account.replace(/[^0-9]/g, '').padStart(9, '0');
}

function formatAmount(amount: number): string {
  return Math.round(amount * 100).toString().padStart(10, '0');
}