import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Sidebar } from './components/Layout/Sidebar';
import { Footer } from './components/Layout/Footer';
import { Dashboard } from './components/Dashboard/Dashboard';
import { CashManagement } from './components/cash/CashManagement';
import { SwapManagement } from './components/swaps/SwapManagement';
import { ReportsPage } from './components/reports/ReportsPage';
import { FXDashboard } from './components/fx/FXDashboard';
import { PaymentManagement } from './components/payments/PaymentManagement';
import { RiskManagement } from './components/risk/RiskManagement';
import { APISettings } from './components/settings/APISettings';
import { UserManagement } from './components/users/UserManagement';
import { HelpPage } from './components/Help/HelpPage';
import { NotificationsPage } from './components/notifications/NotificationsPage';
import { CurrencyManagement } from './components/currency/CurrencyManagement';
import { initializeDatabase } from './db/initialize';

export default function App() {
  const [isDbInitialized, setIsDbInitialized] = useState(false);
  const [dbError, setDbError] = useState<string | null>(null);

  useEffect(() => {
    const init = async () => {
      try {
        await initializeDatabase();
        setIsDbInitialized(true);
      } catch (error) {
        setDbError(error instanceof Error ? error.message : 'Failed to initialize database');
      }
    };
    init();
  }, []);

  if (dbError) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="aws-card p-8 max-w-md w-full">
          <h1 className="text-2xl font-bold text-red-600 mb-4">Database Error</h1>
          <p className="text-gray-600 mb-6">{dbError}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="aws-button-primary w-full"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (!isDbInitialized) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="aws-card p-8 max-w-md w-full">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Initializing...</h1>
          <p className="text-gray-600">Setting up the database...</p>
        </div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <div className="min-h-screen flex flex-col">
        <Sidebar />
        <main className="aws-main flex-grow">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/cash" element={<CashManagement />} />
            <Route path="/swaps" element={<SwapManagement />} />
            <Route path="/fx" element={<FXDashboard />} />
            <Route path="/payments" element={<PaymentManagement />} />
            <Route path="/risk" element={<RiskManagement />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/users" element={<UserManagement />} />
            <Route path="/settings" element={<APISettings />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/notifications" element={<NotificationsPage />} />
            <Route path="/currencies" element={<CurrencyManagement />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}