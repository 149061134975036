import React from 'react';
import { CurrencyRates } from './CurrencyRates';
import { EconomicMetrics } from './EconomicMetrics';
import { NewsWidget } from './NewsWidget';
import { ExposureChart } from './ExposureChart';
import { mockRates, mockMetrics, mockNews, mockExposures } from '../../data/mockFXData';

export function FXDashboard() {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">FX Exposure</h1>
          <p className="text-gray-500 mt-1">Real-time currency rates and market insights</p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <div className="aws-card">
            <div className="p-6 border-b border-gray-200">
              <h2 className="text-lg font-semibold">Currency Exposure</h2>
            </div>
            <div className="p-6">
              <ExposureChart exposures={mockExposures} />
            </div>
          </div>
        </div>
        <div>
          <div className="aws-card">
            <div className="p-6 border-b border-gray-200">
              <h2 className="text-lg font-semibold">Economic Indicators</h2>
            </div>
            <div className="p-6">
              <EconomicMetrics metrics={mockMetrics} />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <CurrencyRates rates={mockRates} />
        </div>
        <div>
          <NewsWidget news={mockNews} />
        </div>
      </div>
    </div>
  );
}