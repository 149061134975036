import React, { useState } from 'react';
import { Plus, ArrowRight } from 'lucide-react';
import type { AccountMapping } from '../../types/settings';

const initialMappings: AccountMapping[] = [
  {
    id: '1',
    bankAccount: {
      id: 'boa-1234',
      name: 'Main Operating Account',
      number: '****1234',
      bank: 'Bank of America'
    },
    ledgerAccount: {
      id: 'gl-1001',
      name: 'Cash and Cash Equivalents',
      code: '1001'
    },
    rules: ['deposits', 'withdrawals']
  }
];

export function AccountMappings() {
  const [mappings, setMappings] = useState<AccountMapping[]>(initialMappings);
  const [showNewForm, setShowNewForm] = useState(false);

  const handleAddMapping = (mapping: Omit<AccountMapping, 'id'>) => {
    setMappings(prev => [...prev, { ...mapping, id: Date.now().toString() }]);
    setShowNewForm(false);
  };

  const handleDeleteMapping = (id: string) => {
    setMappings(prev => prev.filter(mapping => mapping.id !== id));
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Account Mappings</h2>
        <p className="mt-1 text-sm text-gray-500">
          Map bank accounts to ledger accounts for transaction synchronization
        </p>
      </div>

      <div className="p-6">
        <div className="space-y-4">
          {mappings.map(mapping => (
            <div
              key={mapping.id}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div className="flex items-center space-x-4">
                <div>
                  <h3 className="font-medium">{mapping.bankAccount.name}</h3>
                  <p className="text-sm text-gray-500">{mapping.bankAccount.bank}</p>
                </div>
                <ArrowRight className="w-5 h-5 text-gray-400" />
                <div>
                  <h3 className="font-medium">{mapping.ledgerAccount.name}</h3>
                  <p className="text-sm text-gray-500">Code: {mapping.ledgerAccount.code}</p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                {mapping.rules.map(rule => (
                  <span
                    key={rule}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                  >
                    {rule}
                  </span>
                ))}
              </div>
            </div>
          ))}

          {showNewForm ? (
            <MappingForm onSubmit={handleAddMapping} onCancel={() => setShowNewForm(false)} />
          ) : (
            <button
              onClick={() => setShowNewForm(true)}
              className="flex items-center px-4 py-2 text-sm font-medium text-aws-blue hover:text-aws-navy"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add New Mapping
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

interface MappingFormProps {
  onSubmit: (mapping: Omit<AccountMapping, 'id'>) => void;
  onCancel: () => void;
}

function MappingForm({ onSubmit, onCancel }: MappingFormProps) {
  const [formData, setFormData] = useState({
    bankAccount: {
      id: '',
      name: '',
      number: '',
      bank: ''
    },
    ledgerAccount: {
      id: '',
      name: '',
      code: ''
    },
    rules: [] as string[]
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 p-4 bg-white rounded-lg border border-gray-200">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h4 className="text-sm font-medium text-gray-700 mb-2">Bank Account</h4>
          <div className="space-y-3">
            <div>
              <label className="block text-sm font-medium text-gray-700">Account Name</label>
              <input
                type="text"
                value={formData.bankAccount.name}
                onChange={e => setFormData(prev => ({
                  ...prev,
                  bankAccount: { ...prev.bankAccount, name: e.target.value }
                }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Account Number</label>
              <input
                type="text"
                value={formData.bankAccount.number}
                onChange={e => setFormData(prev => ({
                  ...prev,
                  bankAccount: { ...prev.bankAccount, number: e.target.value }
                }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Bank</label>
              <input
                type="text"
                value={formData.bankAccount.bank}
                onChange={e => setFormData(prev => ({
                  ...prev,
                  bankAccount: { ...prev.bankAccount, bank: e.target.value }
                }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>
          </div>
        </div>

        <div>
          <h4 className="text-sm font-medium text-gray-700 mb-2">Ledger Account</h4>
          <div className="space-y-3">
            <div>
              <label className="block text-sm font-medium text-gray-700">Account Name</label>
              <input
                type="text"
                value={formData.ledgerAccount.name}
                onChange={e => setFormData(prev => ({
                  ...prev,
                  ledgerAccount: { ...prev.ledgerAccount, name: e.target.value }
                }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Account Code</label>
              <input
                type="text"
                value={formData.ledgerAccount.code}
                onChange={e => setFormData(prev => ({
                  ...prev,
                  ledgerAccount: { ...prev.ledgerAccount, code: e.target.value }
                }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Transaction Rules</label>
        <div className="mt-2 space-x-2">
          {['deposits', 'withdrawals', 'fees', 'interest'].map(rule => (
            <label key={rule} className="inline-flex items-center">
              <input
                type="checkbox"
                checked={formData.rules.includes(rule)}
                onChange={e => {
                  if (e.target.checked) {
                    setFormData(prev => ({ ...prev, rules: [...prev.rules, rule] }));
                  } else {
                    setFormData(prev => ({ ...prev, rules: prev.rules.filter(r => r !== rule) }));
                  }
                }}
                className="rounded border-gray-300 text-aws-blue focus:ring-aws-blue"
              />
              <span className="ml-2 text-sm text-gray-700 capitalize">{rule}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-aws-blue rounded-md hover:bg-aws-navy"
        >
          Save Mapping
        </button>
      </div>
    </form>
  );
}