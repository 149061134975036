import React from 'react';
import { format } from 'date-fns';
import { X, Download, Mail, FileText } from 'lucide-react';
import type { PaymentInstruction } from '../../types/payment';
import { generatePaymentPDF } from '../../utils/pdfGenerator';
import { sendPaymentNotificationEmail } from '../../utils/emailService';

interface PaymentDetailsProps {
  payment: PaymentInstruction;
  onClose: () => void;
}

export function PaymentDetails({ payment, onClose }: PaymentDetailsProps) {
  const handleDownloadPDF = () => {
    const pdfOutput = generatePaymentPDF(payment);
    const link = document.createElement('a');
    link.href = pdfOutput;
    link.download = `payment-${payment.reference}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSendEmail = async () => {
    try {
      await sendPaymentNotificationEmail(payment, 'recipient@example.com');
      alert('Payment details sent successfully');
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">Payment Details</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="p-6 space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <h3 className="text-sm font-medium text-gray-500">Reference</h3>
              <p className="mt-1 text-sm text-gray-900">{payment.reference}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Status</h3>
              <p className="mt-1 text-sm">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                  ${payment.status === 'approved' ? 'bg-green-100 text-green-800' :
                    payment.status === 'rejected' ? 'bg-red-100 text-red-800' :
                    'bg-yellow-100 text-yellow-800'}`}>
                  {payment.status.replace('_', ' ').toUpperCase()}
                </span>
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <h3 className="text-sm font-medium text-gray-500">Amount</h3>
              <p className="mt-1 text-sm text-gray-900">
                {payment.amount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: payment.currency,
                })}
              </p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Value Date</h3>
              <p className="mt-1 text-sm text-gray-900">
                {format(new Date(payment.valueDate), 'MMM d, yyyy')}
              </p>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <h3 className="text-lg font-medium text-gray-900">Beneficiary Details</h3>
            <div className="mt-4 grid grid-cols-2 gap-6">
              <div>
                <h4 className="text-sm font-medium text-gray-500">Name</h4>
                <p className="mt-1 text-sm text-gray-900">{payment.beneficiary.name}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500">Bank</h4>
                <p className="mt-1 text-sm text-gray-900">{payment.beneficiary.bankDetails.bankName}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500">Account Name</h4>
                <p className="mt-1 text-sm text-gray-900">{payment.beneficiary.bankDetails.accountName}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500">Account Number</h4>
                <p className="mt-1 text-sm text-gray-900">{payment.beneficiary.bankDetails.accountNumber}</p>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <h3 className="text-lg font-medium text-gray-900">Approval History</h3>
            <div className="mt-4 space-y-4">
              {payment.approvalWorkflow.approvalHistory.map((history, index) => (
                <div key={index} className="flex justify-between items-start">
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      Level {history.level} - {history.approver}
                    </p>
                    <p className="text-sm text-gray-500">
                      {format(new Date(history.timestamp), 'MMM d, yyyy HH:mm')}
                    </p>
                  </div>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${history.action === 'approved' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {history.action.toUpperCase()}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="p-6 border-t border-gray-200">
          <div className="flex justify-end space-x-3">
            <button
              onClick={handleSendEmail}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Mail className="w-4 h-4 mr-2" />
              Send Email
            </button>
            <button
              onClick={handleDownloadPDF}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <FileText className="w-4 h-4 mr-2" />
              Download PDF
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}