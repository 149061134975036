import React, { useState, useEffect } from 'react';
import type { CashflowEntry } from '../../types/cash';
import type { CashflowCategory } from '../../types/settings';

interface CashflowFormProps {
  onSubmit: (entry: Omit<CashflowEntry, 'id'>) => void;
  onCancel: () => void;
  initialData?: CashflowEntry;
}

export function CashflowForm({ onSubmit, onCancel, initialData }: CashflowFormProps) {
  const [categories, setCategories] = useState<CashflowCategory[]>([]);
  const [formData, setFormData] = useState({
    date: '',
    type: 'inflow' as const,
    category: '',
    amount: '',
    probability: '100',
    notes: ''
  });

  useEffect(() => {
    // Load categories from localStorage
    const loadCategories = () => {
      const savedCategories = localStorage.getItem('cashflowCategories');
      if (savedCategories) {
        const parsedCategories = JSON.parse(savedCategories);
        // Only show active categories
        setCategories(parsedCategories.filter((c: CashflowCategory) => c.active));
      }
    };

    loadCategories();

    // Add event listener for category updates
    window.addEventListener('cashflowCategoriesUpdated', loadCategories);

    if (initialData) {
      setFormData({
        date: new Date(initialData.date).toISOString().split('T')[0],
        type: initialData.type,
        category: initialData.category,
        amount: initialData.amount.toString(),
        probability: initialData.probability.toString(),
        notes: initialData.notes || ''
      });
    }

    return () => {
      window.removeEventListener('cashflowCategoriesUpdated', loadCategories);
    };
  }, [initialData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      date: new Date(formData.date).toISOString(),
      type: formData.type,
      category: formData.category,
      amount: parseFloat(formData.amount),
      probability: parseInt(formData.probability, 10),
      notes: formData.notes
    });
  };

  const filteredCategories = categories.filter(cat => cat.type === formData.type);

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Date</label>
          <input
            type="date"
            value={formData.date}
            onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Type</label>
          <select
            value={formData.type}
            onChange={(e) => setFormData(prev => ({ 
              ...prev, 
              type: e.target.value as 'inflow' | 'outflow',
              // Reset category when type changes
              category: '' 
            }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
            required
          >
            <option value="inflow">Inflow</option>
            <option value="outflow">Outflow</option>
          </select>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Category</label>
        <select
          value={formData.category}
          onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value }))}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          required
        >
          <option value="">Select a category</option>
          {filteredCategories.map(category => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
        {filteredCategories.length === 0 && (
          <p className="mt-1 text-sm text-amber-600">
            No active categories found for this type. Please add categories in Settings.
          </p>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Amount</label>
          <input
            type="number"
            value={formData.amount}
            onChange={(e) => setFormData(prev => ({ ...prev, amount: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
            min="0"
            step="0.01"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Probability (%)
          </label>
          <input
            type="number"
            value={formData.probability}
            onChange={(e) => setFormData(prev => ({ ...prev, probability: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
            min="0"
            max="100"
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Notes</label>
        <textarea
          value={formData.notes}
          onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          rows={3}
        />
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-aws-blue rounded-md hover:bg-aws-navy"
        >
          {initialData ? 'Update Entry' : 'Add Entry'}
        </button>
      </div>
    </form>
  );
}