import { addMonths, addQuarters, addYears, format } from 'date-fns';
import type { SwapTransaction, SwapSchedule, SwapScheduleEntry } from '../types/swap';

export function generateSwapSchedule(swap: SwapTransaction): SwapSchedule {
  const entries: SwapScheduleEntry[] = [];
  let currentDate = new Date(swap.startDate);
  const endDate = new Date(swap.maturityDate);
  
  // Helper function to get next payment date based on frequency
  const getNextPaymentDate = (date: Date): Date => {
    switch (swap.paymentFrequency) {
      case 'monthly':
        return addMonths(date, 1);
      case 'quarterly':
        return addQuarters(date, 1);
      case 'semi-annual':
        return addMonths(date, 6);
      case 'annual':
        return addYears(date, 1);
      default:
        return addMonths(date, 3); // Default to quarterly
    }
  };

  // Calculate day count factor based on convention
  const getDayCountFactor = (startDate: Date, endDate: Date): number => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const actualDays = Math.round((endDate.getTime() - startDate.getTime()) / millisecondsPerDay);
    
    switch (swap.dayCountConvention) {
      case '30/360':
        return actualDays / 360;
      case 'ACT/360':
        return actualDays / 360;
      case 'ACT/365':
        return actualDays / 365;
      default:
        return actualDays / 360;
    }
  };

  while (currentDate < endDate) {
    const nextPaymentDate = getNextPaymentDate(currentDate);
    const dayCountFactor = getDayCountFactor(currentDate, nextPaymentDate);
    
    // Calculate fixed rate payment
    const fixedRatePayment = swap.notionalAmount * (swap.fixedRate / 100) * dayCountFactor;
    
    // Simulate floating rate (in reality, this would come from market data)
    const projectedFloatingRate = swap.fixedRate * (1 + (Math.random() - 0.5) * 0.1);
    const projectedFloatingPayment = swap.notionalAmount * (projectedFloatingRate / 100) * dayCountFactor;
    
    entries.push({
      paymentDate: format(nextPaymentDate, 'yyyy-MM-dd'),
      notionalAmount: swap.notionalAmount,
      fixedRatePayment,
      projectedFloatingRate,
      projectedFloatingPayment,
      netPayment: projectedFloatingPayment - fixedRatePayment,
    });

    currentDate = nextPaymentDate;
  }

  return {
    swapId: swap.id,
    counterparty: swap.counterparty,
    startDate: swap.startDate,
    maturityDate: swap.maturityDate,
    notionalAmount: swap.notionalAmount,
    fixedRate: swap.fixedRate,
    floatingRateIndex: swap.floatingRateIndex,
    entries,
  };
}

export function exportScheduleToCSV(schedule: SwapSchedule): string {
  const headers = [
    'Payment Date',
    'Notional Amount',
    'Fixed Rate Payment',
    'Projected Rate',
    'Floating Payment',
    'Net Payment',
  ];

  const rows = schedule.entries.map(entry => [
    entry.paymentDate,
    entry.notionalAmount.toString(),
    entry.fixedRatePayment.toString(),
    (entry.projectedFloatingRate * 100).toFixed(4),
    entry.projectedFloatingPayment.toString(),
    entry.netPayment.toString(),
  ]);

  return [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
}