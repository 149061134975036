import React from 'react';
import { Book, Search } from 'lucide-react';

export function HelpPage() {
  return (
    <div className="p-8 space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Help & Documentation</h1>
          <p className="text-gray-500 mt-1">Learn how to use TreasuryWise effectively</p>
        </div>
        <Book className="w-8 h-8 text-gray-400" />
      </div>

      <div className="max-w-3xl mx-auto">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search documentation..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-aws-blue focus:border-aws-blue"
          />
        </div>

        <div className="mt-8 grid gap-6 md:grid-cols-2">
          <div className="aws-card p-6">
            <h2 className="text-lg font-semibold mb-4">Getting Started</h2>
            <ul className="space-y-3">
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  System Overview
                </a>
              </li>
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Dashboard Navigation
                </a>
              </li>
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  User Settings & Preferences
                </a>
              </li>
            </ul>
          </div>

          <div className="aws-card p-6">
            <h2 className="text-lg font-semibold mb-4">Cash Management</h2>
            <ul className="space-y-3">
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Managing Bank Accounts
                </a>
              </li>
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Cash Flow Forecasting
                </a>
              </li>
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Transaction Processing
                </a>
              </li>
            </ul>
          </div>

          <div className="aws-card p-6">
            <h2 className="text-lg font-semibold mb-4">Swap Management</h2>
            <ul className="space-y-3">
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Creating Swap Transactions
                </a>
              </li>
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Swap Schedules
                </a>
              </li>
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Risk Assessment
                </a>
              </li>
            </ul>
          </div>

          <div className="aws-card p-6">
            <h2 className="text-lg font-semibold mb-4">Payment Processing</h2>
            <ul className="space-y-3">
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Creating Payment Instructions
                </a>
              </li>
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Approval Workflows
                </a>
              </li>
              <li>
                <a href="#" className="text-aws-blue hover:text-aws-navy">
                  Payment Reports
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 aws-card p-6">
          <h2 className="text-lg font-semibold mb-4">Need Additional Help?</h2>
          <p className="text-gray-600 mb-4">
            Our support team is available to assist you with any questions or issues you may encounter.
          </p>
          <div className="space-y-4">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                <Book className="w-4 h-4 text-aws-blue" />
              </div>
              <div>
                <h3 className="font-medium">Documentation</h3>
                <p className="text-sm text-gray-500">Browse our detailed documentation</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center mr-3">
                <Search className="w-4 h-4 text-green-600" />
              </div>
              <div>
                <h3 className="font-medium">Knowledge Base</h3>
                <p className="text-sm text-gray-500">Search our knowledge base</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}