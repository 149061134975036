import type { ReportTemplate } from '../types/reports';

export const reportTemplates: ReportTemplate[] = [
  {
    id: 'cash-position',
    name: 'Cash Position Report',
    description: 'Daily cash positions across all currencies',
    type: 'cash',
    defaultFilters: {
      transactionType: ['deposit', 'withdrawal', 'transfer'],
    },
  },
  {
    id: 'swap-exposure',
    name: 'Swap Exposure Analysis',
    description: 'Analysis of current swap positions and risk exposure',
    type: 'swap',
    defaultFilters: {
      status: ['ACTIVE', 'PENDING'],
    },
  },
  {
    id: 'fx-exposure',
    name: 'FX Exposure Report',
    description: 'Currency exposure and hedging positions',
    type: 'fx',
    defaultFilters: {},
  },
  {
    id: 'risk-metrics',
    name: 'Risk Metrics Summary',
    description: 'Comprehensive risk analysis including VaR and exposure metrics',
    type: 'risk',
    defaultFilters: {},
  },
  {
    id: 'compliance',
    name: 'Compliance Report',
    description: 'Regulatory compliance and limit monitoring',
    type: 'compliance',
    defaultFilters: {},
  },
];