import React from 'react';
import { format } from 'date-fns';
import { TrendingUp, TrendingDown } from 'lucide-react';
import type { CashflowEntry } from '../../types/cash';

interface CashflowOverviewProps {
  entries: CashflowEntry[];
}

export function CashflowOverview({ entries }: CashflowOverviewProps) {
  const calculateNetCashflow = () => {
    return entries.reduce((sum, entry) => {
      const weightedAmount = (entry.amount * entry.probability) / 100;
      return sum + (entry.type === 'inflow' ? weightedAmount : -weightedAmount);
    }, 0);
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      notation: 'compact',
      compactDisplay: 'short',
    }).format(amount);
  };

  const netCashflow = calculateNetCashflow();
  const totalInflow = entries
    .filter(e => e.type === 'inflow')
    .reduce((sum, e) => sum + (e.amount * e.probability) / 100, 0);
  const totalOutflow = entries
    .filter(e => e.type === 'outflow')
    .reduce((sum, e) => sum + (e.amount * e.probability) / 100, 0);

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Cashflow Forecast</h2>
      </div>
      <div className="p-6">
        <div className="grid grid-cols-3 gap-6">
          <div className="space-y-2">
            <div className="text-sm font-medium text-gray-500">Net Position</div>
            <div className={`text-2xl font-bold ${netCashflow >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              {formatCurrency(netCashflow)}
            </div>
          </div>
          <div className="space-y-2">
            <div className="text-sm font-medium text-gray-500">Total Inflows</div>
            <div className="flex items-center">
              <TrendingUp className="w-5 h-5 text-green-500 mr-2" />
              <span className="text-2xl font-bold text-gray-900">{formatCurrency(totalInflow)}</span>
            </div>
          </div>
          <div className="space-y-2">
            <div className="text-sm font-medium text-gray-500">Total Outflows</div>
            <div className="flex items-center">
              <TrendingDown className="w-5 h-5 text-red-500 mr-2" />
              <span className="text-2xl font-bold text-gray-900">{formatCurrency(totalOutflow)}</span>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="text-sm font-medium text-gray-500 mb-4">Upcoming Movements</div>
          <div className="space-y-3">
            {entries.slice(0, 3).map(entry => (
              <div key={entry.id} className="flex items-center justify-between">
                <div className="flex items-center">
                  {entry.type === 'inflow' ? (
                    <TrendingUp className="w-4 h-4 text-green-500 mr-2" />
                  ) : (
                    <TrendingDown className="w-4 h-4 text-red-500 mr-2" />
                  )}
                  <div>
                    <div className="text-sm font-medium text-gray-900">{entry.category}</div>
                    <div className="text-xs text-gray-500">
                      {format(new Date(entry.date), 'MMM d, yyyy')}
                    </div>
                  </div>
                </div>
                <div className="text-sm font-medium">
                  <span className={entry.type === 'inflow' ? 'text-green-600' : 'text-red-600'}>
                    {formatCurrency(entry.amount)}
                  </span>
                  <span className="text-xs text-gray-500 ml-1">({entry.probability}%)</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}