import React from 'react';
import { AlertTriangle, TrendingUp, Shield } from 'lucide-react';
import type { RiskMetrics } from '../../types';

interface RiskMetricsCardProps {
  metrics: RiskMetrics;
}

export function RiskMetricsCard({ metrics }: RiskMetricsCardProps) {
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      notation: 'compact',
      compactDisplay: 'short',
    }).format(amount);
  };

  return (
    <div className="flex space-x-4">
      <div className="flex-1 aws-card p-4">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-orange-100">
            <AlertTriangle className="w-6 h-6 text-orange-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-sm font-medium text-gray-500">Credit Exposure</h3>
            <p className="mt-1 text-2xl font-bold text-gray-900">{formatCurrency(5000000)}</p>
          </div>
        </div>
      </div>

      <div className="flex-1 aws-card p-4">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-blue-100">
            <TrendingUp className="w-6 h-6 text-blue-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-sm font-medium text-gray-500">Market Risk</h3>
            <p className="mt-1 text-2xl font-bold text-gray-900">{formatCurrency(10000000)}</p>
          </div>
        </div>
      </div>

      <div className="flex-1 aws-card p-4">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-green-100">
            <Shield className="w-6 h-6 text-green-600" />
          </div>
          <div className="ml-4">
            <h3 className="text-sm font-medium text-gray-500">VaR Score</h3>
            <p className="mt-1 text-2xl font-bold text-gray-900">{metrics.varScore.toFixed(2)}%</p>
          </div>
        </div>
      </div>
    </div>
  );
}