import React from 'react';
import { ArrowLeft, Loader, FileText, FileSpreadsheet, FileDown } from 'lucide-react';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import type { ReportType, Report } from '../../types/reports';

interface ReportViewerProps {
  type: ReportType;
  report: Report | null;
  loading: boolean;
  error: string | null;
  onBack: () => void;
}

export function ReportViewer({ type, report, loading, error, onBack }: ReportViewerProps) {
  const handleExport = (format: 'pdf' | 'excel' | 'csv') => {
    if (!report) return;

    switch (format) {
      case 'pdf':
        exportToPDF(report);
        break;
      case 'excel':
        exportToExcel(report);
        break;
      case 'csv':
        exportToCSV(report);
        break;
    }
  };

  const exportToPDF = (report: Report) => {
    const doc = new jsPDF();
    
    // Add header
    doc.setFontSize(16);
    doc.text(report.title, 14, 15);
    doc.setFontSize(10);
    doc.text(`Generated: ${new Date(report.metadata.generatedAt).toLocaleString()}`, 14, 25);

    // Add filters if any
    const filters = report.metadata.filters;
    if (Object.keys(filters).length > 0) {
      doc.text('Filters:', 14, 35);
      let y = 40;
      Object.entries(filters).forEach(([key, value]) => {
        doc.text(`${key}: ${value}`, 20, y);
        y += 5;
      });
    }

    // Prepare table data
    const tableData = report.data.map(row => 
      report.columns.map(col => col.format ? col.format(row[col.key]) : row[col.key])
    );

    // Add table
    autoTable(doc, {
      startY: Object.keys(filters).length > 0 ? 50 : 35,
      head: [report.columns.map(col => col.label)],
      body: tableData,
    });

    doc.save(`${type}-report-${new Date().toISOString()}.pdf`);
  };

  const exportToExcel = (report: Report) => {
    // Prepare data with formatted values
    const data = report.data.map(row => {
      const formattedRow: Record<string, any> = {};
      report.columns.forEach(col => {
        formattedRow[col.label] = col.format ? col.format(row[col.key]) : row[col.key];
      });
      return formattedRow;
    });

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');
    XLSX.writeFile(wb, `${type}-report-${new Date().toISOString()}.xlsx`);
  };

  const exportToCSV = (report: Report) => {
    // Prepare headers and data
    const headers = report.columns.map(col => col.label);
    const rows = report.data.map(row =>
      report.columns.map(col => col.format ? col.format(row[col.key]) : row[col.key])
    );

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${type}-report-${new Date().toISOString()}.csv`;
    link.click();
  };

  if (loading) {
    return (
      <div className="aws-card p-8 flex items-center justify-center">
        <Loader className="w-6 h-6 animate-spin text-aws-blue mr-2" />
        <span>Generating report...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="aws-card p-8">
        <div className="text-red-600 mb-4">{error}</div>
        <button onClick={onBack} className="aws-button-secondary">
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Reports
        </button>
      </div>
    );
  }

  if (!report) return null;

  return (
    <div className="aws-card p-8">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <button onClick={onBack} className="aws-button-secondary mr-4">
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Reports
          </button>
          <h2 className="text-xl font-semibold">{report.title}</h2>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => handleExport('pdf')}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            <FileText className="w-4 h-4 mr-2" />
            PDF
          </button>
          <button
            onClick={() => handleExport('excel')}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            <FileSpreadsheet className="w-4 h-4 mr-2" />
            Excel
          </button>
          <button
            onClick={() => handleExport('csv')}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            <FileDown className="w-4 h-4 mr-2" />
            CSV
          </button>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="aws-card p-4">
          <h3 className="text-sm font-medium text-gray-500 mb-1">Generated At</h3>
          <p className="text-lg font-semibold">
            {new Date(report.metadata.generatedAt).toLocaleString()}
          </p>
        </div>
        <div className="aws-card p-4">
          <h3 className="text-sm font-medium text-gray-500 mb-1">Total Records</h3>
          <p className="text-lg font-semibold">{report.data.length}</p>
        </div>
        <div className="aws-card p-4">
          <h3 className="text-sm font-medium text-gray-500 mb-1">Report Type</h3>
          <p className="text-lg font-semibold">{type}</p>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="aws-table">
          <thead>
            <tr>
              {report.columns.map((column) => (
                <th key={column.key}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {report.data.map((row, index) => (
              <tr key={index}>
                {report.columns.map((column) => (
                  <td key={column.key}>
                    {column.format ? column.format(row[column.key]) : row[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}