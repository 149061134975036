import React, { useState } from 'react';
import type { FormField, FormData } from '../../types/forms';

interface DynamicFormProps {
  fields: FormField[];
  onSubmit: (data: FormData) => void;
  initialData?: FormData;
}

export function DynamicForm({ fields, onSubmit, initialData = {} }: DynamicFormProps) {
  const [formData, setFormData] = useState<FormData>(initialData);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateField = (field: FormField, value: string) => {
    if (field.required && !value) {
      return 'This field is required';
    }
    if (field.validation) {
      const { min, max, pattern } = field.validation;
      if (field.type === 'number') {
        const numValue = Number(value);
        if (min !== undefined && numValue < min) {
          return `Value must be at least ${min}`;
        }
        if (max !== undefined && numValue > max) {
          return `Value must be no more than ${max}`;
        }
      }
      if (pattern && !new RegExp(pattern).test(value)) {
        return field.validation.message || 'Invalid format';
      }
    }
    return '';
  };

  const handleChange = (field: FormField, value: string) => {
    const error = validateField(field, value);
    setErrors(prev => ({ ...prev, [field.id]: error }));
    setFormData(prev => ({ ...prev, [field.id]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    fields.forEach(field => {
      const value = String(formData[field.id] || '');
      const error = validateField(field, value);
      if (error) {
        newErrors[field.id] = error;
      }
    });

    if (Object.keys(newErrors).length === 0) {
      onSubmit(formData);
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {fields.map(field => (
        <div key={field.id}>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {field.label}
            {field.required && <span className="text-red-500 ml-1">*</span>}
          </label>
          {field.type === 'select' ? (
            <select
              value={String(formData[field.id] || '')}
              onChange={e => handleChange(field, e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">Select an option</option>
              {field.options?.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          ) : field.type === 'textarea' ? (
            <textarea
              value={String(formData[field.id] || '')}
              onChange={e => handleChange(field, e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              rows={4}
              placeholder={field.placeholder}
            />
          ) : (
            <input
              type={field.type}
              value={String(formData[field.id] || '')}
              onChange={e => handleChange(field, e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder={field.placeholder}
            />
          )}
          {errors[field.id] && (
            <p className="mt-1 text-sm text-red-600">{errors[field.id]}</p>
          )}
        </div>
      ))}
      <div className="flex justify-end">
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Submit
        </button>
      </div>
    </form>
  );
}