import React from 'react';
import { Check, X, AlertCircle } from 'lucide-react';
import type { PaymentInstruction } from '../../types/payment';
import { sendPaymentApprovalEmail } from '../../utils/emailService';

interface PaymentApprovalProps {
  payments: PaymentInstruction[];
  onApprove: (paymentId: string) => Promise<boolean>;
  onReject: (paymentId: string) => Promise<boolean>;
}

export function PaymentApproval({ payments, onApprove, onReject }: PaymentApprovalProps) {
  const handleApprove = async (payment: PaymentInstruction) => {
    try {
      const success = await onApprove(payment.id);
      if (success) {
        await sendPaymentApprovalEmail(payment, 'approver@example.com');
        alert('Payment approved successfully');
      }
    } catch (error) {
      console.error('Failed to approve payment:', error);
      alert('Failed to approve payment');
    }
  };

  const handleReject = async (payment: PaymentInstruction) => {
    try {
      const success = await onReject(payment.id);
      if (success) {
        await sendPaymentApprovalEmail(payment, 'approver@example.com');
        alert('Payment rejected');
      }
    } catch (error) {
      console.error('Failed to reject payment:', error);
      alert('Failed to reject payment');
    }
  };

  const pendingPayments = payments.filter(p => p.status === 'pending_approval');

  if (pendingPayments.length === 0) {
    return null;
  }

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold">Pending Approvals</h2>
          <div className="flex items-center text-yellow-600">
            <AlertCircle className="w-5 h-5 mr-2" />
            <span className="text-sm">{pendingPayments.length} payments require approval</span>
          </div>
        </div>
      </div>
      <div className="divide-y divide-gray-200">
        {pendingPayments.map((payment) => (
          <div key={payment.id} className="p-6 flex items-center justify-between">
            <div>
              <h3 className="text-sm font-medium text-gray-900">
                {payment.reference}
              </h3>
              <div className="mt-1 text-sm text-gray-500 space-y-1">
                <p>
                  Amount: {payment.amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: payment.currency,
                  })}
                </p>
                <p>Beneficiary: {payment.beneficiary.name}</p>
                <p>Bank: {payment.beneficiary.bankDetails.bankName}</p>
              </div>
            </div>
            <div className="flex space-x-3">
              <button
                onClick={() => handleReject(payment)}
                className="inline-flex items-center px-3 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50"
              >
                <X className="w-4 h-4 mr-2" />
                Reject
              </button>
              <button
                onClick={() => handleApprove(payment)}
                className="inline-flex items-center px-3 py-2 border border-green-300 shadow-sm text-sm font-medium rounded-md text-green-700 bg-white hover:bg-green-50"
              >
                <Check className="w-4 h-4 mr-2" />
                Approve
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}