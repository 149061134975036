import React from 'react';
import { format } from 'date-fns';
import { Bell, AlertTriangle, CheckCircle, Info, RefreshCw } from 'lucide-react';
import type { Notification } from '../../types/notifications';

const mockNotifications: Notification[] = [
  {
    id: 1,
    title: 'Payment Approval Required',
    message: 'New payment instruction PAY-2024-001 requires your approval',
    type: 'action',
    status: 'unread',
    timestamp: new Date().toISOString(),
    priority: 'high',
    category: 'payments'
  },
  {
    id: 2,
    title: 'Risk Limit Alert',
    message: 'EUR exposure has reached 85% of the defined limit',
    type: 'alert',
    status: 'unread',
    timestamp: new Date(Date.now() - 3600000).toISOString(),
    priority: 'high',
    category: 'risk'
  },
  {
    id: 3,
    title: 'Swap Transaction Completed',
    message: 'Interest rate swap IRS-2024-003 has been successfully processed',
    type: 'info',
    status: 'read',
    timestamp: new Date(Date.now() - 7200000).toISOString(),
    priority: 'low',
    category: 'swaps'
  },
  {
    id: 4,
    title: 'System Update',
    message: 'TreasuryWise will undergo maintenance on March 15, 2024',
    type: 'info',
    status: 'read',
    timestamp: new Date(Date.now() - 86400000).toISOString(),
    priority: 'medium',
    category: 'system'
  }
];

export function NotificationsPage() {
  const [notifications, setNotifications] = React.useState(mockNotifications);
  const [filter, setFilter] = React.useState('all');

  const getIcon = (type: string, priority: string) => {
    switch (type) {
      case 'action':
        return <Bell className={`w-5 h-5 ${priority === 'high' ? 'text-red-500' : 'text-blue-500'}`} />;
      case 'alert':
        return <AlertTriangle className="w-5 h-5 text-yellow-500" />;
      case 'success':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      default:
        return <Info className="w-5 h-5 text-gray-500" />;
    }
  };

  const filteredNotifications = notifications.filter(notification => {
    if (filter === 'all') return true;
    if (filter === 'unread') return notification.status === 'unread';
    return notification.category === filter;
  });

  const markAllAsRead = () => {
    setNotifications(prev => 
      prev.map(notification => ({
        ...notification,
        status: 'read'
      }))
    );
  };

  return (
    <div className="p-8 space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Notifications</h1>
          <p className="text-gray-500 mt-1">View and manage your notifications</p>
        </div>
        <button
          onClick={markAllAsRead}
          className="aws-button-secondary"
        >
          <RefreshCw className="w-4 h-4 mr-2" />
          Mark All as Read
        </button>
      </div>

      <div className="flex space-x-4">
        <button
          onClick={() => setFilter('all')}
          className={`px-4 py-2 rounded-md text-sm font-medium ${
            filter === 'all'
              ? 'bg-aws-blue text-white'
              : 'text-gray-700 hover:bg-gray-100'
          }`}
        >
          All
        </button>
        <button
          onClick={() => setFilter('unread')}
          className={`px-4 py-2 rounded-md text-sm font-medium ${
            filter === 'unread'
              ? 'bg-aws-blue text-white'
              : 'text-gray-700 hover:bg-gray-100'
          }`}
        >
          Unread
        </button>
        <button
          onClick={() => setFilter('payments')}
          className={`px-4 py-2 rounded-md text-sm font-medium ${
            filter === 'payments'
              ? 'bg-aws-blue text-white'
              : 'text-gray-700 hover:bg-gray-100'
          }`}
        >
          Payments
        </button>
        <button
          onClick={() => setFilter('risk')}
          className={`px-4 py-2 rounded-md text-sm font-medium ${
            filter === 'risk'
              ? 'bg-aws-blue text-white'
              : 'text-gray-700 hover:bg-gray-100'
          }`}
        >
          Risk Alerts
        </button>
      </div>

      <div className="aws-card">
        <div className="divide-y divide-gray-200">
          {filteredNotifications.map((notification) => (
            <div
              key={notification.id}
              className={`p-6 flex items-start space-x-4 ${
                notification.status === 'unread' ? 'bg-blue-50' : ''
              }`}
            >
              {getIcon(notification.type, notification.priority)}
              <div className="flex-1 min-w-0">
                <div className="flex justify-between">
                  <p className="text-sm font-medium text-gray-900">
                    {notification.title}
                  </p>
                  <span className="text-xs text-gray-500">
                    {format(new Date(notification.timestamp), 'MMM d, yyyy HH:mm')}
                  </span>
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  {notification.message}
                </p>
                <div className="mt-2 flex items-center space-x-4">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${notification.priority === 'high' ? 'bg-red-100 text-red-800' :
                      notification.priority === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'}`}>
                    {notification.priority.toUpperCase()}
                  </span>
                  <span className="text-xs text-gray-500 capitalize">
                    {notification.category}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}