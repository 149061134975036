import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { CashflowTable } from './CashflowTable';
import { CashflowChart } from './CashflowChart';
import { CashflowUpload } from './CashflowUpload';
import { db } from '../../../db/database';
import type { CashflowEntry } from '../../../types/cash';

export function CashflowForecastSection() {
  const [entries, setEntries] = useState<CashflowEntry[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [period, setPeriod] = useState('monthly');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadEntries();
  }, []);

  const loadEntries = async () => {
    try {
      const loadedEntries = await db.cashflowEntries.toArray();
      setEntries(loadedEntries);
    } catch (error) {
      console.error('Failed to load cashflow entries:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddEntry = async (entry: Omit<CashflowEntry, 'id'>) => {
    try {
      const id = await db.cashflowEntries.add(entry);
      setEntries(prev => [...prev, { ...entry, id }]);
      setShowForm(false);
    } catch (error) {
      console.error('Failed to add entry:', error);
    }
  };

  const handleEditEntry = async (id: number, entry: Omit<CashflowEntry, 'id'>) => {
    try {
      await db.cashflowEntries.update(id, entry);
      setEntries(prev => prev.map(e => e.id === id ? { ...entry, id } : e));
    } catch (error) {
      console.error('Failed to update entry:', error);
    }
  };

  const handleDeleteEntry = async (id: number) => {
    try {
      await db.cashflowEntries.delete(id);
      setEntries(prev => prev.filter(e => e.id !== id));
    } catch (error) {
      console.error('Failed to delete entry:', error);
    }
  };

  const handleUpload = async (newEntries: Omit<CashflowEntry, 'id'>[]) => {
    try {
      const addedEntries = await db.cashflowEntries.bulkAdd(
        newEntries,
        { allKeys: true }
      );
      
      const entriesWithIds = newEntries.map((entry, index) => ({
        ...entry,
        id: addedEntries[index] as number
      }));
      
      setEntries(prev => [...prev, ...entriesWithIds]);
    } catch (error) {
      console.error('Failed to upload entries:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-aws-blue"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Cashflow Forecast</h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage and track your cashflow projections
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <select
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            className="rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue"
          >
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
          </select>
          <button
            onClick={() => setShowForm(true)}
            className="aws-button-primary"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Forecast
          </button>
        </div>
      </div>

      <div className="aws-card">
        <div className="p-6">
          <CashflowChart entries={entries} period={period} />
        </div>
      </div>

      <CashflowUpload onUpload={handleUpload} />

      <CashflowTable
        entries={entries}
        onAddEntry={handleAddEntry}
        onEditEntry={handleEditEntry}
        onDeleteEntry={handleDeleteEntry}
        showForm={showForm}
        onCloseForm={() => setShowForm(false)}
      />
    </div>
  );
}