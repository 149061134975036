import React from 'react';
import { AlertTriangle } from 'lucide-react';

const limits = [
  {
    type: 'FX Position',
    current: 8700000,
    limit: 10000000,
    currency: 'USD',
    usage: 87,
  },
  {
    type: 'Counterparty',
    current: 4500000,
    limit: 7500000,
    currency: 'USD',
    usage: 60,
  },
  {
    type: 'Interest Rate',
    current: 2800000,
    limit: 5000000,
    currency: 'USD',
    usage: 56,
  },
  {
    type: 'Settlement',
    current: 3200000,
    limit: 4000000,
    currency: 'USD',
    usage: 80,
  },
];

export function RiskLimits() {
  const getUsageColor = (usage: number) => {
    if (usage >= 90) return 'bg-red-600';
    if (usage >= 75) return 'bg-yellow-500';
    return 'bg-green-500';
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      maximumFractionDigits: 1,
    }).format(amount);
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Risk Limits</h2>
      </div>
      <div className="p-6">
        <div className="space-y-6">
          {limits.map((limit) => (
            <div key={limit.type}>
              <div className="flex justify-between mb-2">
                <div>
                  <span className="text-sm font-medium text-gray-900">{limit.type}</span>
                  <span className="ml-2 text-sm text-gray-500">
                    ({formatCurrency(limit.current)} / {formatCurrency(limit.limit)})
                  </span>
                </div>
                <div className="flex items-center">
                  {limit.usage >= 75 && (
                    <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2" />
                  )}
                  <span className="text-sm font-medium text-gray-900">
                    {limit.usage}%
                  </span>
                </div>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className={`h-2 rounded-full ${getUsageColor(limit.usage)}`}
                  style={{ width: `${limit.usage}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}