import React from 'react';
import { TrendingUp, TrendingDown, Minus } from 'lucide-react';
import type { CurrencyRate } from '../../types/fx';

interface CurrencyRatesProps {
  rates: CurrencyRate[];
}

export function CurrencyRates({ rates }: CurrencyRatesProps) {
  const getTrendIcon = (change: number) => {
    if (change > 0) return <TrendingUp className="w-4 h-4 text-green-500" />;
    if (change < 0) return <TrendingDown className="w-4 h-4 text-red-500" />;
    return <Minus className="w-4 h-4 text-gray-400" />;
  };

  const getTrendColor = (change: number) => {
    if (change > 0) return 'text-green-600';
    if (change < 0) return 'text-red-600';
    return 'text-gray-600';
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Currency Rates</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Currency Pair
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Rate
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Change
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                % Change
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Trend
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rates.map((rate) => (
              <tr key={rate.pair} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {rate.pair}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {rate.rate.toFixed(4)}
                </td>
                <td className={`px-6 py-4 whitespace-nowrap text-sm ${getTrendColor(rate.change)}`}>
                  {rate.change > 0 ? '+' : ''}{rate.change.toFixed(4)}
                </td>
                <td className={`px-6 py-4 whitespace-nowrap text-sm ${getTrendColor(rate.changePercent)}`}>
                  {rate.changePercent > 0 ? '+' : ''}{rate.changePercent.toFixed(2)}%
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {getTrendIcon(rate.change)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}