import { addMonths } from 'date-fns';
import type { CashflowEntry } from '../types/cash';

const startDate = new Date();

// Generate 12 months of mock data with unique IDs
export const mockCashflowEntries: CashflowEntry[] = Array.from({ length: 12 }).flatMap((_, monthIndex) => {
  const baseDate = addMonths(startDate, monthIndex);
  const baseId = monthIndex * 1000; // Use larger multiplier to avoid ID collisions
  
  return [
    // Regular monthly customer payments
    {
      id: baseId + 1,
      date: baseDate.toISOString(),
      type: 'inflow',
      category: 'Customer Payments',
      amount: 750000 + Math.random() * 250000,
      probability: 95,
      notes: 'Expected customer payments'
    },
    // Operating expenses
    {
      id: baseId + 2,
      date: baseDate.toISOString(),
      type: 'outflow',
      category: 'Operating Expenses',
      amount: 400000 + Math.random() * 100000,
      probability: 100,
      notes: 'Monthly operating expenses'
    },
    // Supplier payments
    {
      id: baseId + 3,
      date: baseDate.toISOString(),
      type: 'outflow',
      category: 'Supplier Payments',
      amount: 200000 + Math.random() * 50000,
      probability: 90,
      notes: 'Supplier invoices'
    },
    // Investment returns (quarterly)
    ...(monthIndex % 3 === 0 ? [{
      id: baseId + 4,
      date: baseDate.toISOString(),
      type: 'inflow',
      category: 'Investment Returns',
      amount: 150000 + Math.random() * 50000,
      probability: 85,
      notes: 'Quarterly investment returns'
    }] : []),
    // Tax payments (quarterly)
    ...(monthIndex % 3 === 2 ? [{
      id: baseId + 5,
      date: baseDate.toISOString(),
      type: 'outflow',
      category: 'Tax Payments',
      amount: 175000 + Math.random() * 25000,
      probability: 100,
      notes: 'Quarterly tax payment'
    }] : [])
  ].filter(Boolean);
});