import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format } from 'date-fns';
import type { PaymentInstruction } from '../types/payment';

export function generatePaymentPDF(payment: PaymentInstruction): string {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const primaryColor = [0, 115, 187]; // AWS Blue
  const secondaryColor = [35, 47, 62]; // AWS Navy

  // Add header with background
  doc.setFillColor(...primaryColor);
  doc.rect(0, 0, pageWidth, 40, 'F');
  
  // Add title
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(24);
  doc.text('TreasuryWise', 14, 25);
  doc.setFontSize(16);
  doc.text('Payment Instruction', 14, 35);

  // Add reference number
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(12);
  doc.text(`Reference: ${payment.reference}`, 14, 60);
  doc.text(`Date: ${format(new Date(payment.valueDate), 'MMM d, yyyy')}`, pageWidth - 80, 60);

  // Add payment details section
  doc.setFillColor(...secondaryColor);
  doc.setTextColor(255, 255, 255);
  doc.rect(14, 70, pageWidth - 28, 10, 'F');
  doc.text('Payment Details', 18, 77);

  // Payment details table
  autoTable(doc, {
    startY: 85,
    theme: 'grid',
    headStyles: { fillColor: [245, 247, 250], textColor: [0, 0, 0] },
    head: [['Field', 'Value']],
    body: [
      ['Amount', payment.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: payment.currency
      })],
      ['Currency', payment.currency],
      ['Payment Method', payment.method.toUpperCase()],
      ['Status', payment.status.replace('_', ' ').toUpperCase()],
    ],
    margin: { left: 14, right: 14 },
  });

  // Add beneficiary section
  const finalY = (doc as any).lastAutoTable.finalY + 15;
  doc.setFillColor(...secondaryColor);
  doc.setTextColor(255, 255, 255);
  doc.rect(14, finalY, pageWidth - 28, 10, 'F');
  doc.text('Beneficiary Details', 18, finalY + 7);

  // Beneficiary details table
  autoTable(doc, {
    startY: finalY + 15,
    theme: 'grid',
    headStyles: { fillColor: [245, 247, 250], textColor: [0, 0, 0] },
    head: [['Field', 'Value']],
    body: [
      ['Name', payment.beneficiary.name],
      ['Bank Name', payment.beneficiary.bankDetails.bankName],
      ['Account Name', payment.beneficiary.bankDetails.accountName],
      ['Account Number', payment.beneficiary.bankDetails.accountNumber],
      ['Routing Number', payment.beneficiary.bankDetails.routingNumber],
      ['SWIFT Code', payment.beneficiary.bankDetails.swiftCode || 'N/A'],
    ],
    margin: { left: 14, right: 14 },
  });

  // Add approval section
  const approvalY = (doc as any).lastAutoTable.finalY + 15;
  doc.setFillColor(...secondaryColor);
  doc.setTextColor(255, 255, 255);
  doc.rect(14, approvalY, pageWidth - 28, 10, 'F');
  doc.text('Approval Status', 18, approvalY + 7);

  // Approval details table
  autoTable(doc, {
    startY: approvalY + 15,
    theme: 'grid',
    headStyles: { fillColor: [245, 247, 250], textColor: [0, 0, 0] },
    head: [['Level', 'Approver', 'Status', 'Date']],
    body: payment.approvalWorkflow.approvalHistory.map(history => [
      `Level ${history.level}`,
      history.approver,
      history.action.toUpperCase(),
      format(new Date(history.timestamp), 'MMM d, yyyy HH:mm'),
    ]),
    margin: { left: 14, right: 14 },
  });

  // Add footer
  const footerY = doc.internal.pageSize.height - 20;
  doc.setTextColor(128, 128, 128);
  doc.setFontSize(10);
  doc.text(format(new Date(), 'MMM d, yyyy HH:mm:ss'), pageWidth - 14, footerY, { align: 'right' });

  return doc.output('datauristring');
}