import { db } from './database';

export async function initializeDatabase() {
  try {
    // Delete existing database to avoid version conflicts
    await db.delete();
    
    // Open the database to trigger migrations
    await db.open();

    // Initialize with sample data
    const cashflowCount = await db.cashflowEntries.count();
    
    if (cashflowCount === 0) {
      await db.cashflowEntries.bulkAdd([
        {
          date: new Date().toISOString(),
          type: 'inflow',
          category: 'Customer Payments',
          amount: 75000,
          probability: 95,
          notes: 'Monthly revenue'
        },
        {
          date: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000).toISOString(),
          type: 'outflow',
          category: 'Operating Expenses',
          amount: 45000,
          probability: 100,
          notes: 'Regular expenses'
        }
      ]);
    }

    // Initialize currency balances
    const currencyCount = await db.currencyBalances.count();
    if (currencyCount === 0) {
      await db.currencyBalances.bulkAdd([
        {
          currency: 'USD',
          balance: 1000000,
          availableBalance: 950000,
          holdBalance: 50000,
          lastUpdated: new Date().toISOString()
        },
        {
          currency: 'EUR',
          balance: 850000,
          availableBalance: 800000,
          holdBalance: 50000,
          lastUpdated: new Date().toISOString()
        }
      ]);
    }
    
    console.log('Database initialized successfully');
    return true;
  } catch (error) {
    console.error('Failed to initialize database:', error);
    throw error;
  }
}