import React from 'react';
import { AlertTriangle, AlertCircle, Info } from 'lucide-react';

const alerts = [
  {
    id: 1,
    type: 'high',
    message: 'EUR exposure approaching limit (87% utilized)',
    timestamp: '2024-02-28T10:30:00Z',
  },
  {
    id: 2,
    type: 'medium',
    message: 'Increased volatility in GBP/USD exchange rate',
    timestamp: '2024-02-28T09:15:00Z',
  },
  {
    id: 3,
    type: 'low',
    message: 'New counterparty limit set for Bank XYZ',
    timestamp: '2024-02-28T08:45:00Z',
  },
];

export function RiskAlerts() {
  const getAlertIcon = (type: string) => {
    switch (type) {
      case 'high':
        return <AlertTriangle className="w-5 h-5 text-red-500" />;
      case 'medium':
        return <AlertCircle className="w-5 h-5 text-yellow-500" />;
      default:
        return <Info className="w-5 h-5 text-blue-500" />;
    }
  };

  const getAlertBackground = (type: string) => {
    switch (type) {
      case 'high':
        return 'bg-red-50';
      case 'medium':
        return 'bg-yellow-50';
      default:
        return 'bg-blue-50';
    }
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Risk Alerts</h2>
      </div>
      <div className="divide-y divide-gray-200">
        {alerts.map((alert) => (
          <div
            key={alert.id}
            className={`p-6 flex items-start space-x-4 ${getAlertBackground(alert.type)}`}
          >
            {getAlertIcon(alert.type)}
            <div className="flex-1 min-w-0">
              <p className="text-sm text-gray-900">{alert.message}</p>
              <p className="text-xs text-gray-500 mt-1">
                {new Date(alert.timestamp).toLocaleString()}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}