import React, { useState } from 'react';
import { X } from 'lucide-react';
import { DynamicForm } from '../common/DynamicForm';
import { swapService } from '../../services/swapService';
import type { SwapTransaction } from '../../types/swap';
import type { FormField } from '../../types/forms';

interface EditSwapModalProps {
  swap: SwapTransaction;
  onClose: () => void;
  onUpdate: () => void;
}

const editSwapFields: FormField[] = [
  {
    id: 'swapType',
    label: 'Swap Type',
    type: 'select',
    options: [
      { value: 'interest', label: 'Interest Rate Swap' },
      { value: 'currency', label: 'Currency Swap' },
      { value: 'cross', label: 'Cross-Currency Swap' },
    ],
    required: true,
  },
  {
    id: 'notionalAmount',
    label: 'Notional Amount',
    type: 'number',
    required: true,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
    required: true,
  },
  {
    id: 'maturityDate',
    label: 'Maturity Date',
    type: 'date',
    required: true,
  },
  {
    id: 'fixedRate',
    label: 'Fixed Rate (%)',
    type: 'number',
    required: true,
  },
  {
    id: 'floatingRateIndex',
    label: 'Floating Rate Index',
    type: 'select',
    options: [
      { value: 'LIBOR', label: 'LIBOR' },
      { value: 'EURIBOR', label: 'EURIBOR' },
      { value: 'SOFR', label: 'SOFR' },
    ],
    required: true,
  },
  {
    id: 'status',
    label: 'Status',
    type: 'select',
    options: [
      { value: 'PENDING', label: 'Pending' },
      { value: 'COMPLETED', label: 'Completed' },
      { value: 'FAILED', label: 'Failed' },
    ],
    required: true,
  },
];

export function EditSwapModal({ swap, onClose, onUpdate }: EditSwapModalProps) {
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (data: Record<string, any>) => {
    try {
      await swapService.updateSwap(swap.id, data);
      onUpdate();
      onClose();
    } catch (err) {
      setError('Failed to update swap');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Edit Swap</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>
        
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}

        <DynamicForm
          fields={editSwapFields}
          onSubmit={handleSubmit}
          initialData={swap}
        />
      </div>
    </div>
  );
}