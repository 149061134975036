import React from 'react';
import { format } from 'date-fns';
import { Edit2, Trash2 } from 'lucide-react';
import { CashflowForm } from '../CashflowForm';
import type { CashflowEntry } from '../../../types/cash';

interface CashflowTableProps {
  entries: CashflowEntry[];
  onAddEntry: (entry: Omit<CashflowEntry, 'id'>) => void;
  onEditEntry: (id: number, entry: Omit<CashflowEntry, 'id'>) => void;
  onDeleteEntry: (id: number) => void;
  showForm: boolean;
  onCloseForm: () => void;
}

export function CashflowTable({
  entries,
  onAddEntry,
  onEditEntry,
  onDeleteEntry,
  showForm,
  onCloseForm,
}: CashflowTableProps) {
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h3 className="text-lg font-semibold">Forecast Entries</h3>
      </div>

      {showForm && (
        <div className="p-6 border-b border-gray-200">
          <CashflowForm
            onSubmit={onAddEntry}
            onCancel={onCloseForm}
          />
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Probability
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {entries.map((entry) => (
              <tr key={entry.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {format(new Date(entry.date), 'MMM d, yyyy')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${entry.type === 'inflow' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {entry.type === 'inflow' ? 'Inflow' : 'Outflow'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {entry.category}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatCurrency(entry.amount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {entry.probability}%
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex space-x-2">
                    <button
                      onClick={() => onEditEntry(entry.id, entry)}
                      className="text-aws-blue hover:text-aws-navy"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => onDeleteEntry(entry.id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}