import React from 'react';
import { TrendingUp, TrendingDown, Minus } from 'lucide-react';
import type { Report } from '../../types/reports';

interface ReportMetricsProps {
  metrics: {
    label: string;
    value: number;
    change?: number;
    currency?: string;
  }[];
}

export function ReportMetrics({ metrics }: ReportMetricsProps) {
  const formatValue = (value: number, currency?: string) => {
    if (currency) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        notation: 'compact',
        maximumFractionDigits: 1,
      }).format(value);
    }
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1,
    }).format(value);
  };

  const getTrendIcon = (change?: number) => {
    if (!change) return <Minus className="w-4 h-4 text-gray-400" />;
    return change > 0 ? 
      <TrendingUp className="w-4 h-4 text-green-500" /> : 
      <TrendingDown className="w-4 h-4 text-red-500" />;
  };

  const getTrendColor = (change?: number) => {
    if (!change) return 'text-gray-500';
    return change > 0 ? 'text-green-600' : 'text-red-600';
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {metrics.map((metric, index) => (
        <div key={index} className="aws-card p-6">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-gray-500">{metric.label}</h3>
            {metric.change !== undefined && (
              <div className={`flex items-center ${getTrendColor(metric.change)}`}>
                {getTrendIcon(metric.change)}
                <span className="ml-1 text-sm">
                  {metric.change > 0 ? '+' : ''}{metric.change}%
                </span>
              </div>
            )}
          </div>
          <p className="mt-2 text-2xl font-semibold text-gray-900">
            {formatValue(metric.value, metric.currency)}
          </p>
        </div>
      ))}
    </div>
  );
}