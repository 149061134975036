import React, { useState } from 'react';
import { format } from 'date-fns';
import { Edit2, Trash2, Calendar } from 'lucide-react';
import { EditSwapModal } from './EditSwapModal';
import { SwapSchedule } from './SwapSchedule';
import { swapService } from '../../services/swapService';
import { generateSwapSchedule } from '../../utils/swapCalculations';
import type { SwapTransaction } from '../../types/swap';

interface SwapTransactionListProps {
  swaps: SwapTransaction[];
}

export function SwapTransactionList({ swaps }: SwapTransactionListProps) {
  const [editingSwap, setEditingSwap] = useState<SwapTransaction | null>(null);
  const [selectedSwap, setSelectedSwap] = useState<SwapTransaction | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const handleDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this swap?')) {
      await swapService.deleteSwap(id);
      setRefreshKey(prev => prev + 1);
    }
  };

  const handleViewSchedule = (swap: SwapTransaction) => {
    setSelectedSwap(swap);
  };

  return (
    <div className="space-y-6">
      <div className="aws-card">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Notional Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Start Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Maturity Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fixed Rate
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Counterparty
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {swaps.map((swap) => (
                <tr key={swap.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {swap.swapType}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {swap.notionalAmount.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                    })}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {format(new Date(swap.startDate), 'MMM d, yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {format(new Date(swap.maturityDate), 'MMM d, yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {swap.fixedRate}%
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {swap.counterparty}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                      ${swap.status === 'COMPLETED' ? 'bg-green-100 text-green-800' : 
                        swap.status === 'FAILED' ? 'bg-red-100 text-red-800' : 
                        'bg-yellow-100 text-yellow-800'}`}>
                      {swap.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleViewSchedule(swap)}
                        className="text-aws-blue hover:text-aws-navy"
                        title="View Schedule"
                      >
                        <Calendar className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => setEditingSwap(swap)}
                        className="text-aws-blue hover:text-aws-navy"
                        title="Edit Swap"
                      >
                        <Edit2 className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => handleDelete(swap.id)}
                        className="text-red-600 hover:text-red-800"
                        title="Delete Swap"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {editingSwap && (
        <EditSwapModal
          swap={editingSwap}
          onClose={() => setEditingSwap(null)}
          onUpdate={() => {
            setRefreshKey(prev => prev + 1);
            setEditingSwap(null);
          }}
        />
      )}

      {selectedSwap && (
        <SwapSchedule
          schedule={generateSwapSchedule(selectedSwap)}
          onClose={() => setSelectedSwap(null)}
        />
      )}
    </div>
  );
}