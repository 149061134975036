import { useState, useEffect } from 'react';
import { db } from '../db/database';
import { format } from 'date-fns';
import type { ReportType, Report, ReportFilter } from '../types/reports';

export function useGenerateReport(type: ReportType | null, filters: ReportFilter) {
  const [report, setReport] = useState<Report | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!type) {
      setReport(null);
      return;
    }

    async function generateReport() {
      setLoading(true);
      setError(null);

      try {
        let data;
        let columns;
        let title;
        let metrics;

        switch (type) {
          case 'cash': {
            const cashTransactions = await db.cashTransactions.toArray();
            const filteredTransactions = cashTransactions.filter(tx => {
              const date = new Date(tx.valueDate);
              return (!filters.startDate || date >= new Date(filters.startDate)) &&
                     (!filters.endDate || date <= new Date(filters.endDate)) &&
                     (!filters.minAmount || tx.amount >= filters.minAmount) &&
                     (!filters.maxAmount || tx.amount <= filters.maxAmount);
            });

            // Calculate metrics
            const totalInflow = filteredTransactions
              .filter(tx => tx.transactionType === 'deposit')
              .reduce((sum, tx) => sum + tx.amount, 0);
            
            const totalOutflow = filteredTransactions
              .filter(tx => tx.transactionType === 'withdrawal')
              .reduce((sum, tx) => sum + tx.amount, 0);

            const previousPeriodInflow = cashTransactions
              .filter(tx => {
                const date = new Date(tx.valueDate);
                const startDate = filters.startDate ? new Date(filters.startDate) : new Date();
                const previousStart = new Date(startDate);
                previousStart.setMonth(previousStart.getMonth() - 1);
                return date >= previousStart && date < startDate && tx.transactionType === 'deposit';
              })
              .reduce((sum, tx) => sum + tx.amount, 0);

            const inflowChange = previousPeriodInflow ? 
              ((totalInflow - previousPeriodInflow) / previousPeriodInflow) * 100 : 0;

            metrics = [
              { 
                label: 'Total Inflow', 
                value: totalInflow, 
                currency: 'USD',
                change: inflowChange
              },
              { 
                label: 'Total Outflow', 
                value: totalOutflow, 
                currency: 'USD' 
              },
              { 
                label: 'Net Position', 
                value: totalInflow - totalOutflow, 
                currency: 'USD' 
              },
              { 
                label: 'Transaction Count', 
                value: filteredTransactions.length 
              }
            ];

            data = filteredTransactions;
            title = 'Cash Flow Analysis';
            columns = [
              { key: 'transactionType', label: 'Type' },
              { 
                key: 'amount', 
                label: 'Amount', 
                format: (value: number) => value.toLocaleString('en-US', { 
                  style: 'currency', 
                  currency: 'USD' 
                })
              },
              { 
                key: 'valueDate', 
                label: 'Date', 
                format: (value: string) => format(new Date(value), 'MMM d, yyyy')
              },
              { key: 'status', label: 'Status' }
            ];
            break;
          }

          case 'fx': {
            const currencyBalances = await db.currencyBalances.toArray();
            const conversions = await db.currencyConversions.toArray();

            const totalExposure = currencyBalances.reduce((sum, bal) => sum + bal.balance, 0);
            const totalConverted = conversions.reduce((sum, conv) => sum + conv.fromAmount, 0);

            metrics = [
              { 
                label: 'Total FX Exposure', 
                value: totalExposure, 
                currency: 'USD' 
              },
              { 
                label: 'Total Converted', 
                value: totalConverted, 
                currency: 'USD' 
              },
              { 
                label: 'Active Currencies', 
                value: currencyBalances.length 
              },
              { 
                label: 'Conversion Count', 
                value: conversions.length 
              }
            ];

            data = conversions;
            title = 'FX Analysis';
            columns = [
              { key: 'fromCurrency', label: 'From' },
              { key: 'toCurrency', label: 'To' },
              { 
                key: 'fromAmount', 
                label: 'Amount', 
                format: (value: number) => value.toLocaleString('en-US', { 
                  style: 'currency', 
                  currency: 'USD' 
                })
              },
              { key: 'rate', label: 'Rate' },
              { 
                key: 'timestamp', 
                label: 'Date', 
                format: (value: string) => format(new Date(value), 'MMM d, yyyy')
              }
            ];
            break;
          }

          default:
            throw new Error('Unsupported report type');
        }

        const reportData = {
          title,
          metadata: {
            generatedAt: new Date().toISOString(),
            generatedBy: 'System',
            filters,
          },
          columns,
          data,
          metrics
        };

        // Save report to database
        await db.reports.add(reportData);
        setReport(reportData);
      } catch (err) {
        console.error('Report generation error:', err);
        setError(err instanceof Error ? err.message : 'Failed to generate report');
      } finally {
        setLoading(false);
      }
    }

    generateReport();
  }, [type, filters]);

  return { report, loading, error };
}