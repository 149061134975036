import type { FormField } from '../../../types/forms';

export const cashTransactionFields: FormField[] = [
  {
    id: 'transactionType',
    label: 'Transaction Type',
    type: 'select',
    options: [
      { value: 'deposit', label: 'Deposit' },
      { value: 'withdrawal', label: 'Withdrawal' },
      { value: 'transfer', label: 'Transfer' },
    ],
    required: true,
  },
  {
    id: 'amount',
    label: 'Amount',
    type: 'number',
    required: true,
    validation: {
      min: 0,
      message: 'Amount must be greater than 0',
    },
  },
  {
    id: 'currency',
    label: 'Currency',
    type: 'select',
    options: [
      { value: 'USD', label: 'USD' },
      { value: 'EUR', label: 'EUR' },
      { value: 'GBP', label: 'GBP' },
    ],
    required: true,
  },
  {
    id: 'valueDate',
    label: 'Value Date',
    type: 'date',
    required: true,
  },
  {
    id: 'counterparty',
    label: 'Counterparty',
    type: 'text',
    required: true,
  },
  {
    id: 'notes',
    label: 'Notes',
    type: 'textarea',
    placeholder: 'Enter any additional details...',
  },
];