interface EmailSettings {
  smtpServer: string;
  smtpPort: string;
  username: string;
  password: string;
  fromAddress: string;
  fromName: string;
  enabled: boolean;
}

function getEmailSettings(): EmailSettings | null {
  const settings = localStorage.getItem('emailSettings');
  return settings ? JSON.parse(settings) : null;
}

export async function sendPaymentApprovalEmail(payment: any, approverEmail: string) {
  const settings = getEmailSettings();
  
  if (!settings?.enabled) {
    console.log('Email notifications are disabled');
    return;
  }

  // In a real implementation, this would use the SMTP settings to send the email
  console.log('Sending approval email:', {
    from: `${settings.fromName} <${settings.fromAddress}>`,
    to: approverEmail,
    subject: `Payment Approval Required - ${payment.reference}`,
    payment,
    smtpSettings: {
      host: settings.smtpServer,
      port: settings.smtpPort,
      auth: {
        user: settings.username,
      }
    }
  });
}

export async function sendPaymentNotificationEmail(payment: any, recipientEmail: string) {
  const settings = getEmailSettings();
  
  if (!settings?.enabled) {
    console.log('Email notifications are disabled');
    return;
  }

  // In a real implementation, this would use the SMTP settings to send the email
  console.log('Sending notification email:', {
    from: `${settings.fromName} <${settings.fromAddress}>`,
    to: recipientEmail,
    subject: `Payment Status Update - ${payment.reference}`,
    payment,
    smtpSettings: {
      host: settings.smtpServer,
      port: settings.smtpPort,
      auth: {
        user: settings.username,
      }
    }
  });
}