import type { Bank } from '../types/bank';
import type { BankAccount, DailyBalance } from '../types/cash';

export const mockBanks: Bank[] = [
  {
    id: 1,
    name: 'Bank of America',
    code: 'BOA',
    country: 'USA',
    accounts: [1, 2],
    facilities: [
      {
        id: 1,
        type: 'revolving',
        limit: 10000000,
        currency: 'USD',
        startDate: '2024-01-01',
        endDate: '2024-12-31',
        interestRate: 5.25,
        utilized: 3000000,
        available: 7000000,
      }
    ],
  },
  {
    id: 2,
    name: 'JP Morgan Chase',
    code: 'JPM',
    country: 'USA',
    accounts: [3, 4],
    facilities: [
      {
        id: 2,
        type: 'overdraft',
        limit: 5000000,
        currency: 'USD',
        startDate: '2024-01-01',
        endDate: '2024-12-31',
        interestRate: 4.75,
        utilized: 0,
        available: 5000000,
      }
    ],
  },
];

export const mockAccounts: BankAccount[] = [
  {
    id: 1,
    bankId: 1,
    accountNumber: '1234567890',
    accountName: 'Main Operating Account',
    accountType: 'current',
    currency: 'USD',
    balance: 5000000,
    availableBalance: 4800000,
    lastUpdated: new Date().toISOString(),
  },
  {
    id: 2,
    bankId: 1,
    accountNumber: '0987654321',
    accountName: 'Term Deposit',
    accountType: 'term',
    currency: 'USD',
    balance: 10000000,
    availableBalance: 10000000,
    lastUpdated: new Date().toISOString(),
  },
  {
    id: 3,
    bankId: 2,
    accountNumber: '2468101214',
    accountName: 'EUR Operating Account',
    accountType: 'current',
    currency: 'EUR',
    balance: 3000000,
    availableBalance: 2900000,
    lastUpdated: new Date().toISOString(),
  },
  {
    id: 4,
    bankId: 2,
    accountNumber: '1357911131',
    accountName: 'Money Market Fund',
    accountType: 'savings',
    currency: 'USD',
    balance: 15000000,
    availableBalance: 15000000,
    lastUpdated: new Date().toISOString(),
  },
];

export const mockDailyBalances: Record<number, DailyBalance[]> = {
  1: [
    {
      date: new Date().toISOString(),
      openingBalance: 4800000,
      closingBalance: 5000000,
      currency: 'USD',
      transactions: 12,
      netMovement: 200000,
    }
  ],
  2: [
    {
      date: new Date().toISOString(),
      openingBalance: 10000000,
      closingBalance: 10000000,
      currency: 'USD',
      transactions: 0,
      netMovement: 0,
    }
  ],
  3: [
    {
      date: new Date().toISOString(),
      openingBalance: 3100000,
      closingBalance: 3000000,
      currency: 'EUR',
      transactions: 5,
      netMovement: -100000,
    }
  ],
  4: [
    {
      date: new Date().toISOString(),
      openingBalance: 14800000,
      closingBalance: 15000000,
      currency: 'USD',
      transactions: 1,
      netMovement: 200000,
    }
  ],
};