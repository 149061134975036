import React, { useState } from 'react';
import { Download, Filter } from 'lucide-react';
import { ReportsList } from './ReportsList';
import { ReportFilters } from './ReportFilters';
import { ReportViewer } from './ReportViewer';
import { ReportMetrics } from './ReportMetrics';
import { AnalyticsReport } from './AnalyticsReport';
import { useGenerateReport } from '../../hooks/useReports';
import type { ReportFilter, ReportType } from '../../types/reports';

export function ReportsPage() {
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<ReportFilter>({});
  const [selectedReport, setSelectedReport] = useState<ReportType | null>(null);
  const { report, loading, error } = useGenerateReport(selectedReport, filters);

  const handleGenerateReport = (type: ReportType) => {
    setSelectedReport(type);
  };

  const availableFilters = [
    {
      type: 'startDate',
      label: 'Start Date'
    },
    {
      type: 'endDate',
      label: 'End Date'
    },
    {
      type: 'transactionType',
      label: 'Transaction Type',
      options: [
        { value: 'deposit', label: 'Deposit' },
        { value: 'withdrawal', label: 'Withdrawal' },
        { value: 'transfer', label: 'Transfer' }
      ]
    },
    {
      type: 'currency',
      label: 'Currency',
      options: [
        { value: 'USD', label: 'USD' },
        { value: 'EUR', label: 'EUR' },
        { value: 'GBP', label: 'GBP' }
      ]
    }
  ];

  const handleExportReport = () => {
    if (!report) return;
    
    const blob = new Blob([JSON.stringify(report, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${selectedReport}-report-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Reports</h1>
          <p className="text-gray-500 mt-1">Generate and analyze treasury reports</p>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="aws-button-secondary"
          >
            <Filter className="w-4 h-4 mr-2" />
            Filters
          </button>
          {report && (
            <button 
              onClick={handleExportReport}
              className="aws-button-primary"
            >
              <Download className="w-4 h-4 mr-2" />
              Export Report
            </button>
          )}
        </div>
      </div>

      {showFilters && (
        <ReportFilters 
          filters={filters} 
          onFilterChange={setFilters}
          availableFilters={availableFilters}
        />
      )}
      
      <div className="grid grid-cols-1 gap-6">
        {!selectedReport ? (
          <ReportsList onGenerateReport={handleGenerateReport} />
        ) : (
          <>
            {report?.metrics && (
              <ReportMetrics metrics={report.metrics} />
            )}
            
            {report && (
              <AnalyticsReport
                data={report.data}
                title={report.title}
                xAxisKey="valueDate"
                series={[
                  { key: 'amount', name: 'Amount', color: '#0073bb' }
                ]}
              />
            )}
            
            <ReportViewer
              type={selectedReport}
              report={report}
              loading={loading}
              error={error}
              onBack={() => setSelectedReport(null)}
            />
          </>
        )}
      </div>
    </div>
  );
}