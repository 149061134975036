import React from 'react';
import { format } from 'date-fns';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import type { CashflowEntry } from '../../../types/cash';

interface CashflowChartProps {
  entries: CashflowEntry[];
  period: string;
}

export function CashflowChart({ entries, period }: CashflowChartProps) {
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      notation: 'compact',
    }).format(value);
  };

  const chartData = entries.reduce((acc: any[], entry) => {
    const date = new Date(entry.date);
    const period = format(date, 'MMM yyyy');
    
    const existingPeriod = acc.find(d => d.period === period);
    if (existingPeriod) {
      if (entry.type === 'inflow') {
        existingPeriod.inflows += (entry.amount * entry.probability) / 100;
      } else {
        existingPeriod.outflows -= (entry.amount * entry.probability) / 100;
      }
      existingPeriod.net = existingPeriod.inflows + existingPeriod.outflows;
    } else {
      acc.push({
        period,
        inflows: entry.type === 'inflow' ? (entry.amount * entry.probability) / 100 : 0,
        outflows: entry.type === 'outflow' ? -(entry.amount * entry.probability) / 100 : 0,
        net: entry.type === 'inflow' ? 
          (entry.amount * entry.probability) / 100 : 
          -(entry.amount * entry.probability) / 100,
      });
    }
    return acc;
  }, []);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
          <p className="font-medium text-gray-900">{label}</p>
          <div className="space-y-1 mt-2">
            <p className="text-green-600">
              Inflows: {formatCurrency(payload[0].value)}
            </p>
            <p className="text-red-600">
              Outflows: {formatCurrency(-payload[1].value)}
            </p>
            <p className={`font-medium ${payload[2].value >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              Net: {formatCurrency(payload[2].value)}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="h-80">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={chartData}
          margin={{ top: 10, right: 30, left: 0, bottom: 30 }}
        >
          <defs>
            <linearGradient id="inflows" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#22c55e" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#22c55e" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="outflows" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ef4444" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#ef4444" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="net" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.2}/>
              <stop offset="95%" stopColor="#3b82f6" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="period"
            angle={-45}
            textAnchor="end"
            height={60}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            tickFormatter={formatCurrency}
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="inflows"
            stroke="#22c55e"
            fillOpacity={1}
            fill="url(#inflows)"
            strokeWidth={2}
          />
          <Area
            type="monotone"
            dataKey="outflows"
            stroke="#ef4444"
            fillOpacity={1}
            fill="url(#outflows)"
            strokeWidth={2}
          />
          <Area
            type="monotone"
            dataKey="net"
            stroke="#3b82f6"
            fillOpacity={1}
            fill="url(#net)"
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}