import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import type { TransactionRule } from '../../types/settings';

const initialRules: TransactionRule[] = [
  {
    id: '1',
    name: 'Bank Fees',
    description: 'Automatically categorize bank fees',
    conditions: [
      { field: 'description', operator: 'contains', value: 'fee' },
      { field: 'type', operator: 'equals', value: 'debit' }
    ],
    action: {
      type: 'categorize',
      category: 'Bank Charges',
      ledgerCode: '6001'
    }
  }
];

export function TransactionRules() {
  const [rules, setRules] = useState<TransactionRule[]>(initialRules);
  const [showNewForm, setShowNewForm] = useState(false);

  const handleAddRule = (rule: Omit<TransactionRule, 'id'>) => {
    setRules(prev => [...prev, { ...rule, id: Date.now().toString() }]);
    setShowNewForm(false);
  };

  const handleDeleteRule = (id: string) => {
    setRules(prev => prev.filter(rule => rule.id !== id));
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Transaction Rules</h2>
        <p className="mt-1 text-sm text-gray-500">
          Configure rules for automatic transaction categorization
        </p>
      </div>

      <div className="p-6">
        <div className="space-y-4">
          {rules.map(rule => (
            <div
              key={rule.id}
              className="p-4 bg-gray-50 rounded-lg"
            >
              <div className="flex items-center justify-between mb-2">
                <h3 className="font-medium">{rule.name}</h3>
                <button
                  onClick={() => handleDeleteRule(rule.id)}
                  className="text-red-600 hover:text-red-800"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
              <p className="text-sm text-gray-500 mb-3">{rule.description}</p>
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-gray-700">Conditions:</h4>
                <div className="space-y-1">
                  {rule.conditions.map((condition, index) => (
                    <div key={index} className="text-sm text-gray-600">
                      {condition.field} {condition.operator} "{condition.value}"
                    </div>
                  ))}
                </div>
                <div className="mt-3">
                  <h4 className="text-sm font-medium text-gray-700">Action:</h4>
                  <div className="text-sm text-gray-600">
                    {rule.action.type}: {rule.action.category} ({rule.action.ledgerCode})
                  </div>
                </div>
              </div>
            </div>
          ))}

          {showNewForm ? (
            <RuleForm onSubmit={handleAddRule} onCancel={() => setShowNewForm(false)} />
          ) : (
            <button
              onClick={() => setShowNewForm(true)}
              className="flex items-center px-4 py-2 text-sm font-medium text-aws-blue hover:text-aws-navy"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add New Rule
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

interface RuleFormProps {
  onSubmit: (rule: Omit<TransactionRule, 'id'>) => void;
  onCancel: () => void;
}

function RuleForm({ onSubmit, onCancel }: RuleFormProps) {
  const [formData, setFormData] = useState<Omit<TransactionRule, 'id'>>({
    name: '',
    description: '',
    conditions: [{ field: '', operator: 'equals', value: '' }],
    action: {
      type: 'categorize',
      category: '',
      ledgerCode: ''
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const addCondition = () => {
    setFormData(prev => ({
      ...prev,
      conditions: [...prev.conditions, { field: '', operator: 'equals', value: '' }]
    }));
  };

  const removeCondition = (index: number) => {
    setFormData(prev => ({
      ...prev,
      conditions: prev.conditions.filter((_, i) => i !== index)
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 p-4 bg-white rounded-lg border border-gray-200">
      <div>
        <label className="block text-sm font-medium text-gray-700">Rule Name</label>
        <input
          type="text"
          value={formData.name}
          onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          value={formData.description}
          onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          rows={2}
        />
      </div>

      <div>
        <div className="flex items-center justify-between mb-2">
          <label className="block text-sm font-medium text-gray-700">Conditions</label>
          <button
            type="button"
            onClick={addCondition}
            className="text-sm text-aws-blue hover:text-aws-navy"
          >
            Add Condition
          </button>
        </div>
        <div className="space-y-2">
          {formData.conditions.map((condition, index) => (
            <div key={index} className="flex items-center space-x-2">
              <select
                value={condition.field}
                onChange={e => {
                  const newConditions = [...formData.conditions];
                  newConditions[index] = { ...condition, field: e.target.value };
                  setFormData(prev => ({ ...prev, conditions: newConditions }));
                }}
                className="block w-1/3 rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              >
                <option value="">Select Field</option>
                <option value="description">Description</option>
                <option value="type">Type</option>
                <option value="amount">Amount</option>
              </select>
              <select
                value={condition.operator}
                onChange={e => {
                  const newConditions = [...formData.conditions];
                  newConditions[index] = { ...condition, operator: e.target.value };
                  setFormData(prev => ({ ...prev, conditions: newConditions }));
                }}
                className="block w-1/3 rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              >
                <option value="equals">Equals</option>
                <option value="contains">Contains</option>
                <option value="startsWith">Starts With</option>
                <option value="endsWith">Ends With</option>
              </select>
              <input
                type="text"
                value={condition.value}
                onChange={e => {
                  const newConditions = [...formData.conditions];
                  newConditions[index] = { ...condition, value: e.target.value };
                  setFormData(prev => ({ ...prev, conditions: newConditions }));
                }}
                className="block w-1/3 rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                placeholder="Value"
                required
              />
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removeCondition(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-2">Action</h4>
        <div className="space-y-3">
          <div>
            <label className="block text-sm font-medium text-gray-700">Category</label>
            <input
              type="text"
              value={formData.action.category}
              onChange={e => setFormData(prev => ({
                ...prev,
                action: { ...prev.action, category: e.target.value }
              }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Ledger Code</label>
            <input
              type="text"
              value={formData.action.ledgerCode}
              onChange={e => setFormData(prev => ({
                ...prev,
                action: { ...prev.action, ledgerCode: e.target.value }
              }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              required
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-aws-blue rounded-md hover:bg-aws-navy"
        >
          Save Rule
        </button>
      </div>
    </form>
  );
}