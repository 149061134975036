import React from 'react';
import { ExternalLink, AlertCircle, AlertTriangle, Info } from 'lucide-react';
import type { NewsItem } from '../../types/fx';

interface NewsWidgetProps {
  news: NewsItem[];
}

export function NewsWidget({ news }: NewsWidgetProps) {
  const getImpactIcon = (impact: 'high' | 'medium' | 'low') => {
    switch (impact) {
      case 'high':
        return <AlertCircle className="w-5 h-5 text-red-500" />;
      case 'medium':
        return <AlertTriangle className="w-5 h-5 text-yellow-500" />;
      default:
        return <Info className="w-5 h-5 text-blue-500" />;
    }
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Market News</h2>
      </div>
      <div className="divide-y divide-gray-200">
        {news.map((item) => (
          <div key={item.id} className="p-6">
            <div className="flex items-start space-x-3">
              {getImpactIcon(item.impact)}
              <div className="flex-1 min-w-0">
                <h3 className="text-sm font-medium text-gray-900">
                  {item.title}
                </h3>
                <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                  {item.summary}
                </p>
                <div className="mt-2 flex items-center space-x-4">
                  <span className="text-xs text-gray-500">
                    {new Date(item.timestamp).toLocaleString()}
                  </span>
                  <span className="text-xs text-gray-500">
                    {item.source}
                  </span>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-xs text-aws-blue hover:text-aws-navy flex items-center"
                  >
                    Read more
                    <ExternalLink className="w-3 h-3 ml-1" />
                  </a>
                </div>
                {item.relatedCurrencies.length > 0 && (
                  <div className="mt-2 flex flex-wrap gap-2">
                    {item.relatedCurrencies.map((currency) => (
                      <span
                        key={currency}
                        className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800"
                      >
                        {currency}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}