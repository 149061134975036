import React from 'react';
import { TrendingUp, TrendingDown, Minus } from 'lucide-react';
import type { EconomicMetric } from '../../types/fx';

interface EconomicMetricsProps {
  metrics: EconomicMetric[];
}

export function EconomicMetrics({ metrics }: EconomicMetricsProps) {
  const getTrendIcon = (trend: 'up' | 'down' | 'stable') => {
    switch (trend) {
      case 'up':
        return <TrendingUp className="w-4 h-4 text-green-500" />;
      case 'down':
        return <TrendingDown className="w-4 h-4 text-red-500" />;
      default:
        return <Minus className="w-4 h-4 text-gray-400" />;
    }
  };

  const getTrendColor = (trend: 'up' | 'down' | 'stable') => {
    switch (trend) {
      case 'up':
        return 'text-green-600';
      case 'down':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="space-y-4">
      {metrics.map((metric) => (
        <div key={metric.name} className="p-4 bg-gray-50 rounded-lg">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-sm font-medium text-gray-500">{metric.name}</h3>
              <div className="mt-1 flex items-baseline">
                <p className="text-2xl font-semibold text-gray-900">
                  {typeof metric.value === 'number' ? metric.value.toFixed(2) : metric.value}
                  {metric.currency && <span className="text-sm ml-1">{metric.currency}</span>}
                </p>
                {metric.period && (
                  <span className="ml-2 text-sm text-gray-500">{metric.period}</span>
                )}
              </div>
            </div>
            <div className={`flex items-center ${getTrendColor(metric.trend)}`}>
              {getTrendIcon(metric.trend)}
              {metric.change && (
                <span className="ml-1 text-sm">
                  {metric.change > 0 ? '+' : ''}{metric.change}%
                </span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}