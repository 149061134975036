import React, { useState } from 'react';
import { CurrencyBalances } from './CurrencyBalances';
import { CurrencyConverter } from './CurrencyConverter';
import { ExchangeRateTable } from './ExchangeRateTable';
import type { Currency, ExchangeRate, CurrencyBalance } from '../../types/currency';

const mockCurrencies: Currency[] = [
  { code: 'USD', name: 'US Dollar', symbol: '$', decimalPlaces: 2, active: true },
  { code: 'EUR', name: 'Euro', symbol: '€', decimalPlaces: 2, active: true },
  { code: 'GBP', name: 'British Pound', symbol: '£', decimalPlaces: 2, active: true },
  { code: 'JPY', name: 'Japanese Yen', symbol: '¥', decimalPlaces: 0, active: true },
];

const mockRates: ExchangeRate[] = [
  { fromCurrency: 'USD', toCurrency: 'EUR', rate: 0.85, timestamp: new Date().toISOString(), source: 'ECB' },
  { fromCurrency: 'USD', toCurrency: 'GBP', rate: 0.73, timestamp: new Date().toISOString(), source: 'ECB' },
  { fromCurrency: 'USD', toCurrency: 'JPY', rate: 110.25, timestamp: new Date().toISOString(), source: 'ECB' },
];

const mockBalances: CurrencyBalance[] = [
  { id: 1, currency: 'USD', balance: 1000000, availableBalance: 950000, holdBalance: 50000, lastUpdated: new Date().toISOString() },
  { id: 2, currency: 'EUR', balance: 850000, availableBalance: 800000, holdBalance: 50000, lastUpdated: new Date().toISOString() },
  { id: 3, currency: 'GBP', balance: 750000, availableBalance: 700000, holdBalance: 50000, lastUpdated: new Date().toISOString() },
];

export function CurrencyManagement() {
  const [selectedCurrency, setSelectedCurrency] = useState<string>('USD');

  const handleConversion = (from: string, to: string, amount: number) => {
    console.log(`Converting ${amount} ${from} to ${to}`);
    // In a real application, this would trigger an API call or database transaction
  };

  return (
    <div className="p-8 space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Currency Management</h1>
        <p className="text-gray-500 mt-1">Manage currency positions and exchange rates</p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <CurrencyBalances balances={mockBalances} baseCurrency={selectedCurrency} />
        <CurrencyConverter
          currencies={mockCurrencies}
          rates={mockRates}
          onConvert={handleConversion}
        />
      </div>

      <ExchangeRateTable rates={mockRates} onRefresh={() => {}} />
    </div>
  );
}