import React from 'react';
import { FileText, ArrowRight } from 'lucide-react';
import { reportTemplates } from '../../data/reportTemplates';
import type { ReportType } from '../../types/reports';

interface ReportsListProps {
  onGenerateReport: (type: ReportType) => void;
}

export function ReportsList({ onGenerateReport }: ReportsListProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {reportTemplates.map((template) => (
        <div key={template.id} className="aws-card hover:shadow-lg transition-shadow">
          <div className="p-6">
            <div className="flex items-start justify-between">
              <div className="flex items-center">
                <FileText className="w-5 h-5 text-aws-blue mr-3" />
                <h3 className="text-lg font-semibold">{template.name}</h3>
              </div>
            </div>
            <p className="text-gray-600 mt-2 text-sm">{template.description}</p>
            <div className="mt-4 flex justify-between items-center">
              <span className="text-xs font-medium px-2 py-1 bg-aws-blue bg-opacity-10 text-aws-blue rounded-full">
                {template.type.toUpperCase()}
              </span>
              <button 
                onClick={() => onGenerateReport(template.type)}
                className="text-aws-blue hover:text-aws-navy flex items-center text-sm font-medium"
              >
                Generate Report
                <ArrowRight className="w-4 h-4 ml-1" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}