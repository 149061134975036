import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../db/database';

export function useCashTransactions() {
  return useLiveQuery(
    () => db.cashTransactions.orderBy('createdAt').reverse().toArray()
  );
}

export function useSwapTransactions() {
  return useLiveQuery(
    () => db.swapTransactions.orderBy('createdAt').reverse().toArray()
  );
}

export function useTransactionStats() {
  return useLiveQuery(async () => {
    const [cashCount, swapCount, totalCashAmount, pendingSwaps] = await Promise.all([
      db.cashTransactions.count(),
      db.swapTransactions.count(),
      db.cashTransactions
        .where('status')
        .equals('COMPLETED')
        .toArray()
        .then(transactions => 
          transactions.reduce((sum, t) => sum + t.amount, 0)
        ),
      db.swapTransactions
        .where('status')
        .equals('PENDING')
        .count(),
    ]);

    return {
      totalCashTransactions: cashCount,
      totalSwapTransactions: swapCount,
      totalCashAmount,
      pendingSwaps,
    };
  });
}