import React from 'react';
import type { FXExposure } from '../../types/fx';

interface ExposureChartProps {
  exposures: FXExposure[];
}

export function ExposureChart({ exposures }: ExposureChartProps) {
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const getUtilizationPercentage = (exposure: number, limit: number) => {
    return (exposure / limit) * 100;
  };

  const getUtilizationColor = (percentage: number) => {
    if (percentage >= 90) return 'bg-red-500';
    if (percentage >= 75) return 'bg-yellow-500';
    return 'bg-green-500';
  };

  return (
    <div className="space-y-6">
      {exposures.map((exposure) => (
        <div key={exposure.currency} className="space-y-2">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-sm font-medium">{exposure.currency}</h3>
              <p className="text-xs text-gray-500">
                Net Exposure: {formatCurrency(exposure.exposure)}
              </p>
            </div>
            <div className="text-right">
              <p className="text-sm font-medium">
                {formatCurrency(exposure.hedged)} hedged
              </p>
              <p className="text-xs text-gray-500">
                {((exposure.hedged / exposure.exposure) * 100).toFixed(1)}% coverage
              </p>
            </div>
          </div>

          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block text-gray-600">
                  {getUtilizationPercentage(exposure.exposure, exposure.limit).toFixed(1)}% of limit
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-gray-600">
                  Limit: {formatCurrency(exposure.limit)}
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
              <div
                style={{
                  width: `${getUtilizationPercentage(exposure.exposure, exposure.limit)}%`,
                }}
                className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                  getUtilizationColor(getUtilizationPercentage(exposure.exposure, exposure.limit))
                }`}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}