import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { DynamicForm } from '../common/DynamicForm';
import { SwapTransactionList } from './SwapTransactionList';
import { db } from '../../db/database';
import { useSwapTransactions } from '../../hooks/useDatabase';
import type { FormField, FormData } from '../../types/forms';
import type { SwapTransaction } from '../../types/swap';

const swapFields: FormField[] = [
  {
    id: 'swapType',
    label: 'Swap Type',
    type: 'select',
    options: [
      { value: 'interest', label: 'Interest Rate Swap' },
      { value: 'currency', label: 'Currency Swap' },
      { value: 'cross', label: 'Cross-Currency Swap' },
    ],
    required: true,
  },
  {
    id: 'notionalAmount',
    label: 'Notional Amount',
    type: 'number',
    required: true,
    validation: {
      min: 0,
      message: 'Amount must be greater than 0',
    },
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
    required: true,
  },
  {
    id: 'maturityDate',
    label: 'Maturity Date',
    type: 'date',
    required: true,
  },
  {
    id: 'fixedRate',
    label: 'Fixed Rate (%)',
    type: 'number',
    required: true,
    validation: {
      min: 0,
      max: 100,
      message: 'Rate must be between 0 and 100',
    },
  },
  {
    id: 'floatingRateIndex',
    label: 'Floating Rate Index',
    type: 'select',
    options: [
      { value: 'LIBOR', label: 'LIBOR' },
      { value: 'EURIBOR', label: 'EURIBOR' },
      { value: 'SOFR', label: 'SOFR' },
    ],
    required: true,
  },
  {
    id: 'counterparty',
    label: 'Counterparty',
    type: 'text',
    required: true,
  },
];

export function SwapManagement() {
  const [showForm, setShowForm] = useState(false);
  const swaps = useSwapTransactions();

  const handleSubmit = async (data: FormData) => {
    await db.swapTransactions.add({
      ...data,
      status: 'PENDING',
    } as Omit<SwapTransaction, 'id' | 'createdAt' | 'updatedAt'>);
    
    setShowForm(false);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Swap Management</h1>
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          <Plus className="w-4 h-4 mr-2" />
          New Swap
        </button>
      </div>

      {showForm ? (
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-lg font-semibold mb-4">New Swap Agreement</h2>
          <DynamicForm fields={swapFields} onSubmit={handleSubmit} />
        </div>
      ) : null}

      <SwapTransactionList swaps={swaps || []} />
    </div>
  );
}