import React, { useState } from 'react';
import { Users } from 'lucide-react';
import { UserList } from './UserList';
import { UserForm } from './UserForm';
import { useUsers } from '../../hooks/useUsers';
import type { User } from '../../types/user';

export function UserManagement() {
  const { users, loading, error } = useUsers();
  const [showForm, setShowForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleAddUser = () => {
    setSelectedUser(null);
    setShowForm(true);
  };

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setShowForm(true);
  };

  if (loading) {
    return (
      <div className="p-8">
        <div className="aws-card p-6">
          <p className="text-gray-600">Loading user data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-8">
        <div className="aws-card p-6">
          <p className="text-red-600">Error loading users: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">User Management</h1>
          <p className="text-gray-500 mt-1">Manage user accounts and permissions</p>
        </div>
        <div className="flex items-center space-x-4">
          <Users className="w-6 h-6 text-gray-400" />
          <button
            onClick={handleAddUser}
            className="aws-button-primary"
          >
            Add User
          </button>
        </div>
      </div>

      {showForm ? (
        <UserForm
          user={selectedUser}
          onClose={() => setShowForm(false)}
          onSuccess={() => setShowForm(false)}
        />
      ) : (
        <UserList
          users={users || []}
          onEdit={handleEditUser}
        />
      )}
    </div>
  );
}