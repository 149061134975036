import React from 'react';
import { Activity, TrendingUp, DollarSign, AlertTriangle } from 'lucide-react';

interface DashboardMetricsProps {
  totalTransactions: number;
  activeSwaps: number;
  fxExposure: number;
  riskScore: number;
}

export function DashboardMetrics({
  totalTransactions,
  activeSwaps,
  fxExposure,
  riskScore,
}: DashboardMetricsProps) {
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      notation: 'compact',
      compactDisplay: 'short',
    }).format(amount);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div className="aws-card p-6">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-aws-blue bg-opacity-10 flex-shrink-0">
            <Activity className="w-6 h-6 text-aws-blue" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">Total&nbsp;Transactions</p>
            <p className="mt-1 text-2xl font-bold text-gray-900">
              {totalTransactions.toLocaleString()}
            </p>
          </div>
        </div>
      </div>

      <div className="aws-card p-6">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-aws-orange bg-opacity-10 flex-shrink-0">
            <TrendingUp className="w-6 h-6 text-aws-orange" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">Active Swaps</p>
            <p className="mt-1 text-2xl font-bold text-gray-900">
              {activeSwaps.toLocaleString()}
            </p>
          </div>
        </div>
      </div>

      <div className="aws-card p-6">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-green-100 flex-shrink-0">
            <DollarSign className="w-6 h-6 text-green-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">FX Exposure</p>
            <p className="mt-1 text-2xl font-bold text-gray-900">
              {formatCurrency(fxExposure)}
            </p>
          </div>
        </div>
      </div>

      <div className="aws-card p-6">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-red-100 flex-shrink-0">
            <AlertTriangle className="w-6 h-6 text-red-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">Risk Score</p>
            <p className="mt-1 text-2xl font-bold text-gray-900">
              {riskScore.toFixed(2)}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}