import React, { useState } from 'react';
import { ArrowRight, RefreshCcw } from 'lucide-react';
import type { Currency, ExchangeRate } from '../../types/currency';

interface CurrencyConverterProps {
  currencies: Currency[];
  rates: ExchangeRate[];
  onConvert: (from: string, to: string, amount: number) => void;
}

export function CurrencyConverter({ currencies, rates, onConvert }: CurrencyConverterProps) {
  const [fromCurrency, setFromCurrency] = useState<string>('USD');
  const [toCurrency, setToCurrency] = useState<string>('EUR');
  const [amount, setAmount] = useState<string>('');
  const [convertedAmount, setConvertedAmount] = useState<number | null>(null);

  const handleConvert = () => {
    const rate = rates.find(r => 
      r.fromCurrency === fromCurrency && r.toCurrency === toCurrency
    )?.rate || 0;

    const converted = parseFloat(amount) * rate;
    setConvertedAmount(converted);
    onConvert(fromCurrency, toCurrency, converted);
  };

  const swapCurrencies = () => {
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
    setConvertedAmount(null);
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Currency Converter</h2>
      </div>
      
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">From Currency</label>
            <select
              value={fromCurrency}
              onChange={(e) => setFromCurrency(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue"
            >
              {currencies.filter(c => c.active).map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.code} - {currency.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center justify-center">
            <button
              onClick={swapCurrencies}
              className="p-2 rounded-full hover:bg-gray-100"
              title="Swap currencies"
            >
              <RefreshCcw className="w-5 h-5 text-gray-500" />
            </button>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">To Currency</label>
            <select
              value={toCurrency}
              onChange={(e) => setToCurrency(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue"
            >
              {currencies.filter(c => c.active).map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.code} - {currency.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700">Amount</label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue"
              placeholder="Enter amount"
              min="0"
              step="0.01"
            />
          </div>
        </div>

        <div className="mt-6 flex justify-between items-center">
          <button
            onClick={handleConvert}
            className="aws-button-primary"
            disabled={!amount || !fromCurrency || !toCurrency}
          >
            Convert
          </button>

          {convertedAmount !== null && (
            <div className="text-right">
              <div className="text-sm text-gray-500">Converted Amount</div>
              <div className="text-xl font-semibold">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: toCurrency,
                }).format(convertedAmount)}
              </div>
            </div>
          )}
        </div>

        {rates.some(r => 
          r.fromCurrency === fromCurrency && r.toCurrency === toCurrency
        ) && (
          <div className="mt-4 text-sm text-gray-500">
            Current rate: 1 {fromCurrency} = {
              rates.find(r => 
                r.fromCurrency === fromCurrency && r.toCurrency === toCurrency
              )?.rate.toFixed(4)
            } {toCurrency}
          </div>
        )}
      </div>
    </div>
  );
}