import React from 'react';
import { TrendingUp, AlertTriangle, Activity, DollarSign } from 'lucide-react';

export function RiskMetrics() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div className="aws-card p-6">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-red-100 flex-shrink-0">
            <AlertTriangle className="w-6 h-6 text-red-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">VaR (95%)</p>
            <p className="mt-1 text-2xl font-bold text-gray-900">$2.5M</p>
          </div>
        </div>
      </div>

      <div className="aws-card p-6">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-orange-100 flex-shrink-0">
            <Activity className="w-6 h-6 text-orange-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">Stress Loss</p>
            <p className="mt-1 text-2xl font-bold text-gray-900">$4.2M</p>
          </div>
        </div>
      </div>

      <div className="aws-card p-6">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-blue-100 flex-shrink-0">
            <TrendingUp className="w-6 h-6 text-blue-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">FX Exposure</p>
            <p className="mt-1 text-2xl font-bold text-gray-900">$8.7M</p>
          </div>
        </div>
      </div>

      <div className="aws-card p-6">
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-green-100 flex-shrink-0">
            <DollarSign className="w-6 h-6 text-green-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">Limit Usage</p>
            <p className="mt-1 text-2xl font-bold text-gray-900">76.4%</p>
          </div>
        </div>
      </div>
    </div>
  );
}