import React, { useEffect, useState } from 'react';
import { Sun, Moon, Monitor } from 'lucide-react';

type Theme = 'light' | 'dark' | 'system';

export function ThemeSettings() {
  const [theme, setTheme] = useState<Theme>(() => {
    const saved = localStorage.getItem('theme');
    return (saved as Theme) || 'light'; // Default to light theme
  });

  useEffect(() => {
    const root = window.document.documentElement;
    
    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      root.classList.toggle('dark', systemTheme === 'dark');
    } else {
      root.classList.toggle('dark', theme === 'dark');
    }
    
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Theme Settings</h2>
        <p className="mt-1 text-sm text-gray-500">
          Customize the application appearance
        </p>
      </div>
      
      <div className="p-6">
        <div className="grid grid-cols-3 gap-4">
          <button
            onClick={() => setTheme('light')}
            className={`p-4 rounded-lg border-2 flex flex-col items-center space-y-2
              ${theme === 'light' ? 'border-aws-blue bg-blue-50' : 'border-gray-200'}`}
          >
            <Sun className="w-6 h-6 text-gray-700" />
            <span className="text-sm font-medium">Light</span>
          </button>
          
          <button
            onClick={() => setTheme('dark')}
            className={`p-4 rounded-lg border-2 flex flex-col items-center space-y-2
              ${theme === 'dark' ? 'border-aws-blue bg-blue-50' : 'border-gray-200'}`}
          >
            <Moon className="w-6 h-6 text-gray-700" />
            <span className="text-sm font-medium">Dark</span>
          </button>
          
          <button
            onClick={() => setTheme('system')}
            className={`p-4 rounded-lg border-2 flex flex-col items-center space-y-2
              ${theme === 'system' ? 'border-aws-blue bg-blue-50' : 'border-gray-200'}`}
          >
            <Monitor className="w-6 h-6 text-gray-700" />
            <span className="text-sm font-medium">System</span>
          </button>
        </div>
      </div>
    </div>
  );
}