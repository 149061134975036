import React, { useState } from 'react';
import { format } from 'date-fns';
import { ChevronDown, ChevronRight, TrendingUp, TrendingDown } from 'lucide-react';
import type { Bank } from '../../../types/bank';
import type { BankAccount, DailyBalance } from '../../../types/cash';

interface BankBalancesProps {
  banks: Bank[];
  accounts: BankAccount[];
  dailyBalances: Record<number, DailyBalance[]>;
}

export function BankBalances({ banks, accounts, dailyBalances }: BankBalancesProps) {
  const [expandedBanks, setExpandedBanks] = useState<Record<number, boolean>>({});

  const toggleBank = (bankId: number) => {
    setExpandedBanks(prev => ({
      ...prev,
      [bankId]: !prev[bankId]
    }));
  };

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-xl font-semibold">Bank Account Balances</h2>
      </div>
      
      <div className="divide-y divide-gray-200">
        {banks.map(bank => (
          <div key={bank.id} className="bg-white">
            <button
              onClick={() => toggleBank(bank.id)}
              className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50 focus:outline-none"
            >
              <div className="flex items-center">
                {expandedBanks[bank.id] ? (
                  <ChevronDown className="w-5 h-5 text-gray-400 mr-2" />
                ) : (
                  <ChevronRight className="w-5 h-5 text-gray-400 mr-2" />
                )}
                <span className="font-medium">{bank.name}</span>
              </div>
              <div className="text-sm text-gray-500">
                {accounts.filter(a => bank.accounts.includes(a.id)).length} Accounts
              </div>
            </button>

            {expandedBanks[bank.id] && (
              <div className="px-6 pb-4">
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead>
                      <tr className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <th className="px-4 py-2">Account</th>
                        <th className="px-4 py-2">Type</th>
                        <th className="px-4 py-2">Currency</th>
                        <th className="px-4 py-2">Balance</th>
                        <th className="px-4 py-2">Available</th>
                        <th className="px-4 py-2">Daily Movement</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {accounts
                        .filter(account => bank.accounts.includes(account.id))
                        .map(account => {
                          const todayBalance = dailyBalances[account.id]?.[0];
                          const movement = todayBalance?.netMovement || 0;
                          
                          return (
                            <tr key={account.id} className="text-sm">
                              <td className="px-4 py-2">
                                <div className="font-medium">{account.accountName}</div>
                                <div className="text-xs text-gray-500">{account.accountNumber}</div>
                              </td>
                              <td className="px-4 py-2 capitalize">{account.accountType}</td>
                              <td className="px-4 py-2">{account.currency}</td>
                              <td className="px-4 py-2">
                                {formatCurrency(account.balance, account.currency)}
                              </td>
                              <td className="px-4 py-2">
                                {formatCurrency(account.availableBalance, account.currency)}
                              </td>
                              <td className="px-4 py-2">
                                <div className="flex items-center">
                                  {movement > 0 ? (
                                    <TrendingUp className="w-4 h-4 text-green-500 mr-1" />
                                  ) : movement < 0 ? (
                                    <TrendingDown className="w-4 h-4 text-red-500 mr-1" />
                                  ) : null}
                                  {formatCurrency(movement, account.currency)}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                {bank.facilities.length > 0 && (
                  <>
                    <h3 className="font-medium mt-6 mb-3">Facilities</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {bank.facilities.map(facility => (
                        <div key={facility.id} className="aws-card p-4">
                          <div className="flex justify-between items-start mb-2">
                            <div>
                              <div className="font-medium capitalize">{facility.type} Facility</div>
                              <div className="text-sm text-gray-500">
                                Expires: {format(new Date(facility.endDate), 'MMM d, yyyy')}
                              </div>
                            </div>
                          </div>
                          <div className="space-y-2">
                            <div className="flex justify-between text-sm">
                              <span>Limit:</span>
                              <span className="font-medium">
                                {formatCurrency(facility.limit, facility.currency)}
                              </span>
                            </div>
                            <div className="flex justify-between text-sm">
                              <span>Utilized:</span>
                              <span className="font-medium">
                                {formatCurrency(facility.utilized, facility.currency)}
                              </span>
                            </div>
                            <div className="flex justify-between text-sm">
                              <span>Available:</span>
                              <span className="font-medium">
                                {formatCurrency(facility.available, facility.currency)}
                              </span>
                            </div>
                            <div className="flex justify-between text-sm">
                              <span>Rate:</span>
                              <span className="font-medium">{facility.interestRate}%</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}