import React, { useState } from 'react';
import { Mail, HelpCircle } from 'lucide-react';

interface EmailSettings {
  smtpServer: string;
  smtpPort: string;
  username: string;
  password: string;
  fromAddress: string;
  fromName: string;
  enabled: boolean;
}

const defaultSettings: EmailSettings = {
  smtpServer: '',
  smtpPort: '587',
  username: '',
  password: '',
  fromAddress: '',
  fromName: '',
  enabled: false,
};

export function EmailSettings() {
  const [settings, setSettings] = useState<EmailSettings>(() => {
    const saved = localStorage.getItem('emailSettings');
    return saved ? JSON.parse(saved) : defaultSettings;
  });

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    localStorage.setItem('emailSettings', JSON.stringify(settings));
    alert('Email settings saved successfully');
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-lg font-semibold">Email Settings</h2>
            <p className="mt-1 text-sm text-gray-500">
              Configure email notifications for payment approvals
            </p>
          </div>
          <Mail className="w-6 h-6 text-gray-400" />
        </div>
      </div>

      <form onSubmit={handleSave} className="p-6 space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <label className="text-sm font-medium text-gray-700 mr-2">Enable Email Notifications</label>
            <div className="relative group">
              <HelpCircle className="w-4 h-4 text-gray-400" />
              <div className="hidden group-hover:block absolute z-10 w-64 p-2 mt-2 text-sm text-white bg-gray-900 rounded-lg">
                Enable to send email notifications for payment approvals and status updates
              </div>
            </div>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={settings.enabled}
              onChange={e => setSettings(prev => ({ ...prev, enabled: e.target.checked }))}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">SMTP Server</label>
            <input
              type="text"
              value={settings.smtpServer}
              onChange={e => setSettings(prev => ({ ...prev, smtpServer: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              placeholder="smtp.example.com"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">SMTP Port</label>
            <input
              type="text"
              value={settings.smtpPort}
              onChange={e => setSettings(prev => ({ ...prev, smtpPort: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              placeholder="587"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Username</label>
            <input
              type="text"
              value={settings.username}
              onChange={e => setSettings(prev => ({ ...prev, username: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              value={settings.password}
              onChange={e => setSettings(prev => ({ ...prev, password: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">From Address</label>
            <input
              type="email"
              value={settings.fromAddress}
              onChange={e => setSettings(prev => ({ ...prev, fromAddress: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              placeholder="treasury@company.com"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">From Name</label>
            <input
              type="text"
              value={settings.fromName}
              onChange={e => setSettings(prev => ({ ...prev, fromName: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              placeholder="Treasury Department"
              required
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-aws-blue rounded-md hover:bg-aws-navy"
          >
            Save Settings
          </button>
        </div>
      </form>
    </div>
  );
}