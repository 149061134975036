import React, { useState } from 'react';
import { format } from 'date-fns';
import { FileText, Download } from 'lucide-react';
import type { PaymentInstruction } from '../../types/payment';
import { generateABAFile } from '../../utils/abaGenerator';
import { PaymentDetails } from './PaymentDetails';

interface PaymentListProps {
  payments: PaymentInstruction[];
}

export function PaymentList({ payments }: PaymentListProps) {
  const [selectedPayment, setSelectedPayment] = useState<PaymentInstruction | null>(null);

  const handleDownloadABA = (payment: PaymentInstruction) => {
    const abaRecord = {
      bsb: payment.beneficiary.bankDetails.routingNumber,
      accountNumber: payment.beneficiary.bankDetails.accountNumber,
      amount: payment.amount,
      accountName: payment.beneficiary.bankDetails.accountName,
      reference: payment.reference,
      traceBSB: '123-456', // Your bank's BSB
      traceAccount: '123456789', // Your bank's account number
    };

    const descriptiveRecord = {
      financialInstitution: 'ANZ',
      userIdentification: 'COMPANY001',
      description: 'PAYMENTS',
      processingDate: new Date(payment.valueDate),
    };

    const abaContent = generateABAFile([abaRecord], descriptiveRecord);
    
    // Create and download the file
    const blob = new Blob([abaContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `payment-${payment.reference}.aba`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getStatusColor = (status: PaymentInstruction['status']) => {
    switch (status) {
      case 'approved':
        return 'bg-green-100 text-green-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      case 'pending_approval':
        return 'bg-yellow-100 text-yellow-800';
      case 'processed':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <>
      <div className="aws-card">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold">Payment Instructions</h2>
        </div>
        
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Reference
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Beneficiary
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Value Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {payments.map((payment) => (
                <tr key={payment.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {payment.reference}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {payment.amount.toLocaleString('en-US', {
                      style: 'currency',
                      currency: payment.currency,
                    })}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div>
                      <div className="font-medium">{payment.beneficiary.name}</div>
                      <div className="text-gray-500">{payment.beneficiary.bankDetails.bankName}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(new Date(payment.valueDate), 'MMM d, yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(payment.status)}`}>
                      {payment.status.replace('_', ' ').toUpperCase()}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleDownloadABA(payment)}
                        className="text-aws-blue hover:text-aws-navy"
                        title="Download ABA File"
                      >
                        <Download className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => setSelectedPayment(payment)}
                        className="text-aws-blue hover:text-aws-navy"
                        title="View Payment Details"
                      >
                        <FileText className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedPayment && (
        <PaymentDetails
          payment={selectedPayment}
          onClose={() => setSelectedPayment(null)}
        />
      )}
    </>
  );
}