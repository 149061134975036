import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Edit2 } from 'lucide-react';
import type { CashflowCategory } from '../../types/settings';

const defaultCategories: CashflowCategory[] = [
  { id: '1', name: 'Customer Payments', type: 'inflow', description: 'Revenue from customers', active: true },
  { id: '2', name: 'Operating Expenses', type: 'outflow', description: 'Regular business expenses', active: true },
  { id: '3', name: 'Supplier Payments', type: 'outflow', description: 'Payments to vendors', active: true },
  { id: '4', name: 'Investment Returns', type: 'inflow', description: 'Returns from investments', active: true },
  { id: '5', name: 'Tax Payments', type: 'outflow', description: 'Tax obligations', active: true },
];

export function CategorySettings() {
  const [categories, setCategories] = useState<CashflowCategory[]>(() => {
    const saved = localStorage.getItem('cashflowCategories');
    return saved ? JSON.parse(saved) : defaultCategories;
  });
  const [editingCategory, setEditingCategory] = useState<CashflowCategory | null>(null);
  const [showForm, setShowForm] = useState(false);

  // Save categories to localStorage and dispatch event whenever they change
  useEffect(() => {
    localStorage.setItem('cashflowCategories', JSON.stringify(categories));
    window.dispatchEvent(new Event('cashflowCategoriesUpdated'));
  }, [categories]);

  const handleSave = (category: CashflowCategory) => {
    const newCategories = editingCategory
      ? categories.map(c => c.id === editingCategory.id ? category : c)
      : [...categories, { ...category, id: Date.now().toString() }];
    
    setCategories(newCategories);
    setEditingCategory(null);
    setShowForm(false);
  };

  const handleDelete = (id: string) => {
    const newCategories = categories.map(c => 
      c.id === id ? { ...c, active: false } : c
    );
    setCategories(newCategories);
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-lg font-semibold">Cashflow Categories</h2>
            <p className="text-sm text-gray-500">Manage categories for cashflow forecasting</p>
          </div>
          <button
            onClick={() => {
              setEditingCategory(null);
              setShowForm(true);
            }}
            className="aws-button-primary"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Category
          </button>
        </div>
      </div>

      <div className="p-6">
        {showForm && (
          <CategoryForm
            category={editingCategory}
            onSave={handleSave}
            onCancel={() => {
              setEditingCategory(null);
              setShowForm(false);
            }}
          />
        )}

        <div className="mt-6 space-y-4">
          {categories.filter(c => c.active).map(category => (
            <div
              key={category.id}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div>
                <h3 className="font-medium text-gray-900">{category.name}</h3>
                <div className="mt-1 flex items-center space-x-4">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${category.type === 'inflow' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {category.type}
                  </span>
                  {category.description && (
                    <span className="text-sm text-gray-500">{category.description}</span>
                  )}
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => {
                    setEditingCategory(category);
                    setShowForm(true);
                  }}
                  className="text-aws-blue hover:text-aws-navy"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
                <button
                  onClick={() => handleDelete(category.id)}
                  className="text-red-600 hover:text-red-800"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

interface CategoryFormProps {
  category?: CashflowCategory | null;
  onSave: (category: CashflowCategory) => void;
  onCancel: () => void;
}

function CategoryForm({ category, onSave, onCancel }: CategoryFormProps) {
  const [formData, setFormData] = useState({
    name: category?.name || '',
    type: category?.type || 'inflow',
    description: category?.description || '',
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({
      id: category?.id || Date.now().toString(),
      ...formData,
      active: true,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Category Name</label>
        <input
          type="text"
          value={formData.name}
          onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Type</label>
        <select
          value={formData.type}
          onChange={e => setFormData(prev => ({ ...prev, type: e.target.value as 'inflow' | 'outflow' }))}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          required
        >
          <option value="inflow">Inflow</option>
          <option value="outflow">Outflow</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          value={formData.description}
          onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          rows={3}
        />
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-aws-blue rounded-md hover:bg-aws-navy"
        >
          {category ? 'Update Category' : 'Add Category'}
        </button>
      </div>
    </form>
  );
}