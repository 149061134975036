import React from 'react';
import { Filter } from 'lucide-react';
import type { ReportFilter } from '../../types/reports';

interface ReportFiltersProps {
  filters: ReportFilter;
  onFilterChange: (filters: ReportFilter) => void;
  availableFilters: {
    type: keyof ReportFilter;
    label: string;
    options?: { value: string; label: string }[];
  }[];
}

export function ReportFilters({ filters, onFilterChange, availableFilters }: ReportFiltersProps) {
  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center">
          <Filter className="w-5 h-5 text-gray-400 mr-2" />
          <h2 className="text-lg font-semibold">Report Filters</h2>
        </div>
      </div>
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {availableFilters.map(filter => (
            <div key={filter.type}>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {filter.label}
              </label>
              {filter.options ? (
                <select
                  value={filters[filter.type] as string || ''}
                  onChange={(e) => onFilterChange({ ...filters, [filter.type]: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                >
                  <option value="">All</option>
                  {filter.options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={filter.type === 'startDate' || filter.type === 'endDate' ? 'date' : 'text'}
                  value={filters[filter.type] as string || ''}
                  onChange={(e) => onFilterChange({ ...filters, [filter.type]: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}