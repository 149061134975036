import React from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';
import type { CurrencyBalance } from '../../types/currency';

interface CurrencyBalancesProps {
  balances: CurrencyBalance[];
  baseCurrency?: string;
}

export function CurrencyBalances({ balances, baseCurrency = 'USD' }: CurrencyBalancesProps) {
  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Currency Balances</h2>
        <p className="text-sm text-gray-500 mt-1">Current positions across all currencies</p>
      </div>
      
      <div className="divide-y divide-gray-200">
        {balances.map((balance) => (
          <div key={balance.id} className="p-6 flex items-center justify-between">
            <div>
              <div className="flex items-center">
                <span className="text-lg font-semibold">{balance.currency}</span>
                <span className="ml-2 text-sm text-gray-500">
                  {balance.currency !== baseCurrency && `(${baseCurrency} Base)`}
                </span>
              </div>
              <div className="mt-1 text-sm text-gray-500">
                Last updated: {new Date(balance.lastUpdated).toLocaleString()}
              </div>
            </div>
            
            <div className="text-right">
              <div className="text-lg font-semibold">
                {formatCurrency(balance.balance, balance.currency)}
              </div>
              <div className="flex items-center justify-end mt-1">
                <div className="text-sm text-gray-500 mr-4">
                  Available: {formatCurrency(balance.availableBalance, balance.currency)}
                </div>
                <div className="text-sm text-gray-500">
                  Hold: {formatCurrency(balance.holdBalance, balance.currency)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}