import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import type { SwapSchedule } from '../types/swap';

export function exportSwapScheduleToPDF(schedule: SwapSchedule) {
  const doc = new jsPDF();
  
  // Add header
  doc.setFontSize(16);
  doc.text(`Swap Schedule - ${schedule.counterparty}`, 14, 15);
  
  // Add swap details
  doc.setFontSize(10);
  doc.text([
    `Start Date: ${format(new Date(schedule.startDate), 'MMM d, yyyy')}`,
    `Maturity Date: ${format(new Date(schedule.maturityDate), 'MMM d, yyyy')}`,
    `Notional Amount: ${schedule.notionalAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`,
    `Fixed Rate: ${schedule.fixedRate}%`,
    `Floating Rate Index: ${schedule.floatingRateIndex}`,
  ], 14, 25);

  // Prepare table data
  const tableData = schedule.entries.map(entry => [
    format(new Date(entry.paymentDate), 'MMM d, yyyy'),
    entry.notionalAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    entry.fixedRatePayment.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    `${(entry.projectedFloatingRate * 100).toFixed(4)}%`,
    entry.projectedFloatingPayment.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    entry.netPayment.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
  ]);

  // Add table
  autoTable(doc, {
    startY: 60,
    head: [['Payment Date', 'Notional', 'Fixed Payment', 'Proj. Rate', 'Float Payment', 'Net Payment']],
    body: tableData,
  });

  doc.save(`swap-schedule-${schedule.swapId}-${new Date().getTime()}.pdf`);
}

export function exportSwapScheduleToExcel(schedule: SwapSchedule) {
  const data = schedule.entries.map(entry => ({
    'Payment Date': format(new Date(entry.paymentDate), 'MMM d, yyyy'),
    'Notional Amount': entry.notionalAmount,
    'Fixed Rate Payment': entry.fixedRatePayment,
    'Projected Rate': entry.projectedFloatingRate,
    'Floating Payment': entry.projectedFloatingPayment,
    'Net Payment': entry.netPayment,
  }));

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Swap Schedule');
  XLSX.writeFile(wb, `swap-schedule-${schedule.swapId}-${new Date().getTime()}.xlsx`);
}

export function exportSwapScheduleToCSV(schedule: SwapSchedule) {
  const headers = ['Payment Date', 'Notional Amount', 'Fixed Rate Payment', 'Projected Rate', 'Floating Payment', 'Net Payment'];
  const rows = schedule.entries.map(entry => [
    format(new Date(entry.paymentDate), 'MMM d, yyyy'),
    entry.notionalAmount,
    entry.fixedRatePayment,
    entry.projectedFloatingRate,
    entry.projectedFloatingPayment,
    entry.netPayment,
  ]);

  const csvContent = [
    headers.join(','),
    ...rows.map(row => row.join(','))
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `swap-schedule-${schedule.swapId}-${new Date().getTime()}.csv`;
  link.click();
}