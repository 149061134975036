import React, { useState } from 'react';
import { X, HelpCircle } from 'lucide-react';
import type { PaymentMethod, BankDetails } from '../../types/payment';

interface PaymentFormProps {
  onClose: () => void;
}

export function PaymentForm({ onClose }: PaymentFormProps) {
  const [formData, setFormData] = useState({
    reference: '',
    amount: '',
    currency: 'USD',
    valueDate: '',
    method: 'ach' as PaymentMethod,
    beneficiary: {
      name: '',
      bankDetails: {
        bankName: '',
        accountName: '',
        accountNumber: '',
        routingNumber: '',
        swiftCode: ''
      }
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // TODO: Implement payment creation logic
    onClose();
  };

  const handleBankDetailsChange = (field: keyof BankDetails, value: string) => {
    setFormData(prev => ({
      ...prev,
      beneficiary: {
        ...prev.beneficiary,
        bankDetails: {
          ...prev.beneficiary.bankDetails,
          [field]: value
        }
      }
    }));
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">New Payment Instruction</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="w-6 h-6" />
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="p-6 space-y-6">
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Payment Reference
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              value={formData.reference}
              onChange={e => setFormData(prev => ({ ...prev, reference: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Payment Method
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              value={formData.method}
              onChange={e => setFormData(prev => ({ ...prev, method: e.target.value as PaymentMethod }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              required
            >
              <option value="ach">ACH</option>
              <option value="wire">Wire Transfer</option>
              <option value="internal">Internal Transfer</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Amount
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="number"
              value={formData.amount}
              onChange={e => setFormData(prev => ({ ...prev, amount: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              min="0"
              step="0.01"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Currency
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              value={formData.currency}
              onChange={e => setFormData(prev => ({ ...prev, currency: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              required
            >
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Value Date
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="date"
              value={formData.valueDate}
              onChange={e => setFormData(prev => ({ ...prev, valueDate: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              required
            />
          </div>
        </div>

        <div className="border-t border-gray-200 pt-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Beneficiary Details</h3>
          
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Beneficiary Name
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                value={formData.beneficiary.name}
                onChange={e => setFormData(prev => ({
                  ...prev,
                  beneficiary: { ...prev.beneficiary, name: e.target.value }
                }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Bank Name
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                value={formData.beneficiary.bankDetails.bankName}
                onChange={e => handleBankDetailsChange('bankName', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6 mt-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Account Name
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                value={formData.beneficiary.bankDetails.accountName}
                onChange={e => handleBankDetailsChange('accountName', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Account Number
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                value={formData.beneficiary.bankDetails.accountNumber}
                onChange={e => handleBankDetailsChange('accountNumber', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6 mt-4">
            <div>
              <div className="flex items-center">
                <label className="block text-sm font-medium text-gray-700">
                  Routing Number
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <div className="ml-2 relative group">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                  <div className="hidden group-hover:block absolute z-10 w-64 p-2 mt-2 text-sm text-white bg-gray-900 rounded-lg">
                    For US banks, enter the 9-digit ABA routing number
                  </div>
                </div>
              </div>
              <input
                type="text"
                value={formData.beneficiary.bankDetails.routingNumber}
                onChange={e => handleBankDetailsChange('routingNumber', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
                required
              />
            </div>

            <div>
              <div className="flex items-center">
                <label className="block text-sm font-medium text-gray-700">
                  SWIFT Code
                </label>
                <div className="ml-2 relative group">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                  <div className="hidden group-hover:block absolute z-10 w-64 p-2 mt-2 text-sm text-white bg-gray-900 rounded-lg">
                    Required for international wire transfers
                  </div>
                </div>
              </div>
              <input
                type="text"
                value={formData.beneficiary.bankDetails.swiftCode}
                onChange={e => handleBankDetailsChange('swiftCode', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-aws-blue rounded-md hover:bg-aws-navy"
          >
            Create Payment
          </button>
        </div>
      </form>
    </div>
  );
}