import React from 'react';
import { Shield, AlertTriangle, TrendingUp, Activity } from 'lucide-react';
import { RiskMetrics } from './RiskMetrics';
import { RiskExposures } from './RiskExposures';
import { RiskLimits } from './RiskLimits';
import { RiskAlerts } from './RiskAlerts';

export function RiskManagement() {
  return (
    <div className="p-8 space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Risk Management</h1>
          <p className="text-gray-500 mt-1">Monitor and manage treasury risks</p>
        </div>
        <Shield className="w-8 h-8 text-gray-400" />
      </div>

      <RiskMetrics />
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <RiskExposures />
        <RiskLimits />
      </div>

      <RiskAlerts />
    </div>
  );
}