import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const exposureData = [
  { currency: 'USD', exposure: 5000000, limit: 7000000 },
  { currency: 'EUR', exposure: 3200000, limit: 5000000 },
  { currency: 'GBP', exposure: 2100000, limit: 3000000 },
  { currency: 'JPY', exposure: 1800000, limit: 2500000 },
  { currency: 'AUD', exposure: 900000, limit: 1500000 },
];

export function RiskExposures() {
  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Currency Exposures</h2>
      </div>
      <div className="p-6">
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={exposureData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="currency" />
              <YAxis
                tickFormatter={(value) => 
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    notation: 'compact',
                    maximumFractionDigits: 1,
                  }).format(value)
                }
              />
              <Tooltip
                formatter={(value) => 
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0,
                  }).format(value)
                }
              />
              <Bar dataKey="exposure" fill="#0073bb" name="Current Exposure" />
              <Bar dataKey="limit" fill="#ff9900" name="Limit" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}