import Dexie from 'dexie';
import type { Table } from 'dexie';
import type { CashTransaction, CashflowEntry } from '../types/cash';
import type { SwapTransaction } from '../types/swap';
import type { CurrencyBalance, CurrencyConversion } from '../types/currency';
import type { Report } from '../types/reports';

class TreasuryDatabase extends Dexie {
  cashTransactions!: Table<CashTransaction>;
  swapTransactions!: Table<SwapTransaction>;
  cashflowEntries!: Table<CashflowEntry>;
  currencyBalances!: Table<CurrencyBalance>;
  currencyConversions!: Table<CurrencyConversion>;
  reports!: Table<Report>;

  constructor() {
    super('treasuryDB');
    
    this.version(1).stores({
      cashTransactions: '++id, transactionType, currency, valueDate, status, createdAt, counterparty',
      swapTransactions: '++id, swapType, startDate, maturityDate, status, createdAt, counterparty',
      cashflowEntries: '++id, date, type, category, amount, probability',
      currencyBalances: '++id, currency, balance, lastUpdated',
      currencyConversions: '++id, fromCurrency, toCurrency, timestamp, status',
      reports: '++id, type, title, generatedAt'
    });

    // Add hooks for automatic timestamps
    this.cashTransactions.hook('creating', (primKey, obj) => {
      obj.createdAt = new Date().toISOString();
      obj.updatedAt = new Date().toISOString();
    });

    this.swapTransactions.hook('creating', (primKey, obj) => {
      obj.createdAt = new Date().toISOString();
      obj.updatedAt = new Date().toISOString();
    });

    this.reports.hook('creating', (primKey, obj) => {
      obj.generatedAt = new Date().toISOString();
    });
  }

  async resetDatabase() {
    await this.delete();
    await this.open();
  }
}

const db = new TreasuryDatabase();

export { db };