import type { CurrencyRate, EconomicMetric, NewsItem, FXExposure } from '../types/fx';

export const mockRates: CurrencyRate[] = [
  {
    pair: 'EUR/USD',
    rate: 1.0865,
    change: -0.0023,
    changePercent: -0.21,
    timestamp: new Date().toISOString(),
  },
  {
    pair: 'GBP/USD',
    rate: 1.2634,
    change: 0.0045,
    changePercent: 0.36,
    timestamp: new Date().toISOString(),
  },
  {
    pair: 'USD/JPY',
    rate: 150.45,
    change: 0.65,
    changePercent: 0.43,
    timestamp: new Date().toISOString(),
  },
  {
    pair: 'USD/CHF',
    rate: 0.8845,
    change: -0.0012,
    changePercent: -0.14,
    timestamp: new Date().toISOString(),
  },
  {
    pair: 'AUD/USD',
    rate: 0.6545,
    change: 0.0034,
    changePercent: 0.52,
    timestamp: new Date().toISOString(),
  },
];

export const mockMetrics: EconomicMetric[] = [
  {
    name: 'US Interest Rate',
    value: 5.25,
    change: 0,
    trend: 'stable',
    period: 'Federal Funds Rate',
  },
  {
    name: 'EUR Interest Rate',
    value: 4.50,
    change: 0.25,
    trend: 'up',
    period: 'ECB Rate',
  },
  {
    name: 'US CPI YoY',
    value: 3.1,
    change: -0.2,
    trend: 'down',
    period: 'Feb 2024',
  },
  {
    name: 'EUR CPI YoY',
    value: 2.8,
    change: -0.3,
    trend: 'down',
    period: 'Feb 2024',
  },
  {
    name: 'US GDP Growth',
    value: 2.5,
    change: 0.3,
    trend: 'up',
    period: 'Q4 2023',
  },
];

export const mockNews: NewsItem[] = [
  {
    id: '1',
    title: 'Fed Signals Potential Rate Cuts in 2024',
    summary: 'Federal Reserve officials indicated they expect to cut interest rates three times in 2024 as inflation continues to moderate.',
    source: 'Reuters',
    url: '#',
    timestamp: new Date().toISOString(),
    impact: 'high',
    relatedCurrencies: ['USD', 'EUR', 'JPY'],
  },
  {
    id: '2',
    title: 'ECB Maintains Hawkish Stance on Rates',
    summary: 'European Central Bank President Christine Lagarde emphasized the need to maintain restrictive policy despite easing inflation pressures.',
    source: 'Bloomberg',
    url: '#',
    timestamp: new Date(Date.now() - 3600000).toISOString(),
    impact: 'medium',
    relatedCurrencies: ['EUR', 'GBP'],
  },
  {
    id: '3',
    title: 'Bank of Japan Discusses Exit from Negative Rates',
    summary: 'Sources suggest BOJ officials are actively discussing conditions for ending negative interest rate policy.',
    source: 'Financial Times',
    url: '#',
    timestamp: new Date(Date.now() - 7200000).toISOString(),
    impact: 'high',
    relatedCurrencies: ['JPY', 'USD'],
  },
];

export const mockExposures: FXExposure[] = [
  {
    currency: 'EUR',
    exposure: 25000000,
    hedged: 20000000,
    unhedged: 5000000,
    limit: 30000000,
  },
  {
    currency: 'GBP',
    exposure: 15000000,
    hedged: 10000000,
    unhedged: 5000000,
    limit: 20000000,
  },
  {
    currency: 'JPY',
    exposure: 2000000000,
    hedged: 1500000000,
    unhedged: 500000000,
    limit: 2500000000,
  },
  {
    currency: 'CHF',
    exposure: 8000000,
    hedged: 6000000,
    unhedged: 2000000,
    limit: 10000000,
  },
];