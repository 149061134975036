import React, { useState } from 'react';
import { Plus, Trash2, HelpCircle } from 'lucide-react';
import type { BankAPIConnection } from '../../types/settings';

const initialConnections: BankAPIConnection[] = [
  {
    id: '1',
    bankName: 'Bank of America',
    apiEndpoint: 'https://api.bankofamerica.com/v1',
    apiKey: '**********************',
    status: 'active'
  }
];

interface TooltipProps {
  content: string;
}

function Tooltip({ content }: TooltipProps) {
  return (
    <div className="group relative inline-block">
      <HelpCircle className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-help" />
      <div className="hidden group-hover:block absolute z-10 w-64 p-2 mt-2 text-sm text-white bg-gray-900 rounded-lg shadow-lg -left-1/2 transform -translate-x-1/2">
        {content}
        <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-2 h-2 rotate-45 bg-gray-900"></div>
      </div>
    </div>
  );
}

export function BankAPIConfig() {
  const [connections, setConnections] = useState<BankAPIConnection[]>(initialConnections);
  const [showNewForm, setShowNewForm] = useState(false);

  const handleAddConnection = (connection: Omit<BankAPIConnection, 'id'>) => {
    setConnections(prev => [...prev, { ...connection, id: Date.now().toString() }]);
    setShowNewForm(false);
  };

  const handleDeleteConnection = (id: string) => {
    if (window.confirm('Are you sure you want to delete this connection? This action cannot be undone.')) {
      setConnections(prev => prev.filter(conn => conn.id !== id));
    }
  };

  return (
    <div className="aws-card">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center space-x-2">
          <h2 className="text-lg font-semibold">Bank API Connections</h2>
          <Tooltip content="Configure secure API connections to your bank accounts for automatic transaction synchronization." />
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Set up and manage your bank API integrations
        </p>
      </div>

      <div className="p-6">
        <div className="space-y-4">
          {connections.map(connection => (
            <div
              key={connection.id}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div>
                <h3 className="font-medium">{connection.bankName}</h3>
                <p className="text-sm text-gray-500">{connection.apiEndpoint}</p>
                <div className="mt-1">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${connection.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {connection.status}
                  </span>
                </div>
              </div>
              <button
                onClick={() => handleDeleteConnection(connection.id)}
                className="text-red-600 hover:text-red-800"
                title="Delete connection"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          ))}

          {showNewForm ? (
            <ConnectionForm onSubmit={handleAddConnection} onCancel={() => setShowNewForm(false)} />
          ) : (
            <button
              onClick={() => setShowNewForm(true)}
              className="flex items-center px-4 py-2 text-sm font-medium text-aws-blue hover:text-aws-navy"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add New Connection
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

interface ConnectionFormProps {
  onSubmit: (connection: Omit<BankAPIConnection, 'id'>) => void;
  onCancel: () => void;
}

function ConnectionForm({ onSubmit, onCancel }: ConnectionFormProps) {
  const [formData, setFormData] = useState({
    bankName: '',
    apiEndpoint: '',
    apiKey: '',
    status: 'inactive' as const
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleChange = (field: keyof typeof formData, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 p-4 bg-white rounded-lg border border-gray-200">
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <HelpCircle className="h-5 w-5 text-blue-400" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-blue-700">
              To set up a new bank connection, you'll need:
            </p>
            <ul className="mt-2 text-sm text-blue-700 list-disc list-inside">
              <li>Your bank's API credentials</li>
              <li>The API endpoint URL</li>
              <li>Appropriate access permissions</li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div className="flex items-center space-x-2">
          <label className="block text-sm font-medium text-gray-700">Bank Name</label>
          <Tooltip content="Enter the official name of your bank" />
        </div>
        <input
          type="text"
          value={formData.bankName}
          onChange={e => handleChange('bankName', e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          placeholder="e.g., Bank of America"
          required
        />
      </div>

      <div>
        <div className="flex items-center space-x-2">
          <label className="block text-sm font-medium text-gray-700">API Endpoint</label>
          <Tooltip content="The base URL for your bank's API (must start with https://)" />
        </div>
        <input
          type="url"
          value={formData.apiEndpoint}
          onChange={e => handleChange('apiEndpoint', e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          placeholder="https://api.yourbank.com/v1"
          pattern="https://.*"
          title="Must be a valid HTTPS URL"
          required
        />
      </div>

      <div>
        <div className="flex items-center space-x-2">
          <label className="block text-sm font-medium text-gray-700">API Key</label>
          <Tooltip content="Your secure API key provided by the bank" />
        </div>
        <input
          type="password"
          value={formData.apiKey}
          onChange={e => handleChange('apiKey', e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-aws-blue focus:ring-aws-blue sm:text-sm"
          placeholder="Enter your API key"
          required
        />
        <p className="mt-1 text-xs text-gray-500">
          Your API key will be encrypted before storage
        </p>
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-aws-blue rounded-md hover:bg-aws-navy"
        >
          Save Connection
        </button>
      </div>
    </form>
  );
}