import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { DynamicForm } from '../../common/DynamicForm';
import { CashTransactionList } from './CashTransactionList';
import { db } from '../../../db/database';
import { useCashTransactions } from '../../../hooks/useDatabase';
import { cashTransactionFields } from './cashTransactionFields';
import type { FormData } from '../../../types/forms';
import type { CashTransaction } from '../../../types/cash';

export function CashTransactionSection() {
  const [showForm, setShowForm] = useState(false);
  const transactions = useCashTransactions();

  const handleSubmit = async (data: FormData) => {
    await db.cashTransactions.add({
      ...data,
      status: 'PENDING',
    } as Omit<CashTransaction, 'id' | 'createdAt' | 'updatedAt'>);
    
    setShowForm(false);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Cash Transactions</h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage your cash transactions
          </p>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="aws-button-primary"
        >
          <Plus className="w-4 h-4 mr-2" />
          New Transaction
        </button>
      </div>

      {showForm && (
        <div className="aws-card p-6">
          <h2 className="text-lg font-semibold mb-4">New Cash Transaction</h2>
          <DynamicForm fields={cashTransactionFields} onSubmit={handleSubmit} />
        </div>
      )}

      <CashTransactionList transactions={transactions || []} />
    </div>
  );
}