import { db } from '../db/database';
import type { SwapTransaction } from '../types/swap';

export const swapService = {
  async createSwap(swap: Omit<SwapTransaction, 'id' | 'createdAt' | 'updatedAt'>) {
    const now = new Date().toISOString();
    const newSwap = {
      ...swap,
      createdAt: now,
      updatedAt: now,
    };
    
    const id = await db.swapTransactions.add(newSwap);
    return id;
  },

  async getSwaps() {
    return await db.swapTransactions
      .orderBy('createdAt')
      .reverse()
      .toArray();
  },

  async getSwapById(id: number) {
    return await db.swapTransactions.get(id);
  },

  async updateSwap(id: number, swap: Partial<SwapTransaction>) {
    const updatedSwap = {
      ...swap,
      updatedAt: new Date().toISOString(),
    };
    
    await db.swapTransactions.update(id, updatedSwap);
    return true;
  },

  async deleteSwap(id: number) {
    await db.swapTransactions.delete(id);
    return true;
  }
};