import React from 'react';
import { format } from 'date-fns';
import { ArrowUpRight, ArrowDownLeft } from 'lucide-react';
import type { Transaction } from '../../types';

interface TransactionsListProps {
  transactions: Transaction[];
}

export function TransactionsList({ transactions }: TransactionsListProps) {
  return (
    <div className="divide-y divide-gray-200">
      {transactions.map((transaction) => (
        <div key={transaction.id} className="py-4 first:pt-0 last:pb-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {transaction.type === 'CASH' ? (
                <ArrowUpRight className="w-5 h-5 text-green-500 mr-3" />
              ) : (
                <ArrowDownLeft className="w-5 h-5 text-aws-blue mr-3" />
              )}
              <div>
                <p className="text-sm font-medium text-gray-900">
                  {transaction.type} - {transaction.counterparty}
                </p>
                <p className="text-sm text-gray-500">
                  {format(new Date(transaction.date), 'MMM d, yyyy')}
                </p>
              </div>
            </div>
            <div className="text-right">
              <p className="text-sm font-medium text-gray-900">
                {transaction.currency} {transaction.amount.toLocaleString()}
              </p>
              <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium
                ${transaction.status === 'COMPLETED' ? 'bg-green-100 text-green-800' :
                  transaction.status === 'PENDING' ? 'bg-yellow-100 text-yellow-800' :
                  'bg-red-100 text-red-800'}`}>
                {transaction.status}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}