import React from 'react';

export function Logo() {
  const logo = localStorage.getItem('companyLogo');

  return (
    <div className="flex items-center space-x-4">
      {logo ? (
        <img 
          src={logo}
          alt="Company Logo"
          className="h-8 w-auto"
        />
      ) : (
        <div className="h-8 w-8 bg-white/10 rounded flex items-center justify-center">
          <span className="text-white font-bold">T</span>
        </div>
      )}
      <div className="text-xl font-bold text-white">
        TreasuryWise
      </div>
    </div>
  );
}