import { useState, useEffect } from 'react';
import type { PaymentInstruction } from '../types/payment';

// Mock data for demonstration
const mockPayments: PaymentInstruction[] = [
  {
    id: '1',
    reference: 'PAY-2024-001',
    amount: 50000,
    currency: 'USD',
    valueDate: new Date().toISOString(),
    method: 'ach',
    status: 'pending_approval',
    beneficiary: {
      name: 'Acme Corp',
      bankDetails: {
        bankName: 'Bank of America',
        accountName: 'Acme Corp Operations',
        accountNumber: '1234567890',
        routingNumber: '026009593',
        swiftCode: 'BOFAUS3N'
      }
    },
    approvalWorkflow: {
      currentLevel: 1,
      maxLevels: 2,
      approvers: ['user1', 'user2'],
      approvalHistory: []
    },
    createdBy: 'john.doe',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: '2',
    reference: 'PAY-2024-002',
    amount: 75000,
    currency: 'USD',
    valueDate: new Date().toISOString(),
    method: 'wire',
    status: 'pending_approval',
    beneficiary: {
      name: 'Global Trading Ltd',
      bankDetails: {
        bankName: 'Chase Bank',
        accountName: 'Global Trading Operations',
        accountNumber: '0987654321',
        routingNumber: '021000021',
        swiftCode: 'CHASUS33'
      }
    },
    approvalWorkflow: {
      currentLevel: 1,
      maxLevels: 2,
      approvers: ['user1', 'user2'],
      approvalHistory: []
    },
    createdBy: 'jane.smith',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }
];

export function usePayments() {
  const [payments, setPayments] = useState<PaymentInstruction[]>(mockPayments);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const approvePayment = async (paymentId: string) => {
    try {
      setPayments(prev => prev.map(payment => 
        payment.id === paymentId 
          ? { ...payment, status: 'approved' as const }
          : payment
      ));
      return true;
    } catch (err) {
      setError('Failed to approve payment');
      return false;
    }
  };

  const rejectPayment = async (paymentId: string) => {
    try {
      setPayments(prev => prev.map(payment => 
        payment.id === paymentId 
          ? { ...payment, status: 'rejected' as const }
          : payment
      ));
      return true;
    } catch (err) {
      setError('Failed to reject payment');
      return false;
    }
  };

  return { 
    payments, 
    loading, 
    error,
    approvePayment,
    rejectPayment
  };
}