import React from 'react';
import { RiskMetricsCard } from './RiskMetricsCard';
import { TransactionsList } from './TransactionsList';
import { DashboardMetrics } from './DashboardMetrics';
import { CashflowChart } from '../cash/CashflowChart';
import { CashflowOverview } from './CashflowOverview';
import { useCashTransactions, useSwapTransactions, useTransactionStats } from '../../hooks/useDatabase';
import { mockCashflowEntries } from '../../data/mockCashflowData';
import type { Transaction } from '../../types';

export function Dashboard() {
  const stats = useTransactionStats();
  const cashTransactions = useCashTransactions();
  const swapTransactions = useSwapTransactions();

  // Combine and sort transactions by date
  const recentTransactions = React.useMemo(() => {
    if (!cashTransactions || !swapTransactions) return [];

    const combined: Transaction[] = [
      ...cashTransactions.map(tx => ({
        id: `cash-${tx.id}`,
        type: 'CASH',
        amount: tx.amount,
        currency: tx.currency,
        date: tx.valueDate,
        status: tx.status,
        counterparty: tx.counterparty,
      })),
      ...swapTransactions.map(swap => ({
        id: `swap-${swap.id}`,
        type: 'SWAP',
        amount: swap.notionalAmount,
        currency: 'USD',
        date: swap.startDate,
        status: swap.status,
        counterparty: swap.counterparty,
      }))
    ];

    return combined
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .slice(0, 5);
  }, [cashTransactions, swapTransactions]);

  // Calculate risk metrics
  const riskMetrics = React.useMemo(() => {
    if (!cashTransactions || !swapTransactions) return null;
    
    return {
      creditExposure: 5000000,
      marketRisk: 10000000,
      varScore: 2.50,
      lastUpdated: new Date().toISOString(),
    };
  }, [cashTransactions, swapTransactions]);

  if (!stats || !riskMetrics) {
    return (
      <div className="p-8">
        <div className="aws-card p-6">
          <p className="text-gray-600">Loading dashboard data...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-[1680px] mx-auto w-full p-6 space-y-6">
      <header>
        <h1 className="text-3xl font-bold text-gray-900">Treasury Dashboard</h1>
        <p className="text-gray-500 mt-2">Welcome to TreasuryWise</p>
      </header>

      <DashboardMetrics
        totalTransactions={stats.totalCashTransactions + stats.totalSwapTransactions}
        activeSwaps={stats.pendingSwaps}
        fxExposure={stats.totalCashAmount}
        riskScore={riskMetrics.varScore}
      />

      <RiskMetricsCard metrics={riskMetrics} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <CashflowOverview entries={mockCashflowEntries} />
        <div className="aws-card">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-xl font-semibold">Recent Transactions</h2>
          </div>
          <div className="p-6">
            <TransactionsList transactions={recentTransactions} />
          </div>
        </div>
      </div>

      <div className="aws-card">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Cashflow Forecast</h2>
        </div>
        <div className="p-6">
          <CashflowChart entries={mockCashflowEntries} period="monthly" />
        </div>
      </div>
    </div>
  );
}